import { RefresherCustomEvent } from '@ionic/angular';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PersonDetailed } from '@tutor-app/models';
import { ChangePersonRequest } from '../../../api/hrm/person/person.request';

// Compiler will infer the type
//eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/naming-convention
export const usersActions = createActionGroup({
  source: 'User/API',
  events: {
    'Init User': emptyProps(),
    'Load User Success': props<{ user: PersonDetailed }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    'Load User Failure': props<{ error: any }>(),
    'Reload User': props<{ refreshEvent: RefresherCustomEvent }>(),
    'Reload User Success': props<{ user: PersonDetailed; refreshEvent: RefresherCustomEvent }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    'Reload User Failure': props<{ error: any; refreshEvent: RefresherCustomEvent }>(),
    'Update User': props<{ request: ChangePersonRequest }>(),
    'Update User Success': props<{ user: PersonDetailed }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    'Update User Failure': props<{ error: any }>(),
    'Post Avatar': props<{ file: Blob }>(),
    'Post Avatar Success': emptyProps(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    'Post Avatar Failure': props<{ error: any }>(),
    'Delete Avatar': emptyProps(),
    'Delete Avatar Success': emptyProps(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    'Delete Avatar Failure': props<{ error: any }>(),
    'Init User On Boarding': emptyProps(),
    'Set User On Boarding': props<{ value: Date }>(),
    'Set User On Boarding Success': props<{ value: boolean }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    'Set User On Boarding Failure': props<{ error: any }>(),
    'Init User Home Availability Tip': emptyProps(),
    'Set User Home Availability Tip': props<{ value: boolean }>(),
    'Set User Home Availability Tip Success': props<{ value: boolean }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    'Set UserHome Availability Tip Failure': props<{ error: any }>(),

  },
});
