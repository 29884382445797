import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserStoreService } from './+state/user-store.service';
import { UserEffects } from './+state/user.effects';
import { userFeatureKey, userReducer } from './+state/user.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(userFeatureKey, userReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [
    UserStoreService,
  ],
})
export class UserDataAccessModule { }
