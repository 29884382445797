import { Expose, Type } from 'class-transformer';
import { Coordinate } from './coordinate.model';

/**
 * standard version of the Location resource from Scheduler-api
 *
 * It's named LocationModel since Location is already a globally defined
 * javascript Object.
 */
export class LocationModelOld {
  /**
   * The Gps coordinates of this location.
   *
   * These coordinates are used to show the location within googleMaps on the
   * {@see AssignmentDetailPage}
   */
  @Type(() => Coordinate)
  @Expose() public coordinates!: Coordinate;

  /**
   * The unique Identifier of the location.
   *
   * Hrm doesn't return an id.
   */
  @Expose() public id?: string;

  /**
   * The name of the location.
   *
   * The name of the location is shown on the Assignment card.
   * {@see AssignmentCardComponent }
   */
  @Expose() public name?: string;

  @Expose() public city?: string;

  public get getName(): string {
    return this.name ?? '';
  }

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.getName;
  }
}
