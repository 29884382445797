import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Hydra } from '@techniek-team/class-transformer';
import { isDefined, VOID } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { CandidateRejectionApi, DeclineReasonsSelectors } from '@tutor-app/data-access-decline-reasons';
import { Assignment, CandidateRejection } from '@tutor-app/models';
import { concatMap, forkJoin, of, tap, withLatestFrom } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith } from 'rxjs/operators';
import { AssignmentApi } from '../api/assignment.api';
import { assignmentsActions } from '../assignment.actions';
import { AssignmentSelectors } from '../assignment.selectors';

@Injectable()
export class AssignmentSelfAssignEffects {
  private init$;

  private reload$;

  private next$;

  private failure$;

  private rejectAssignment$;

  private rejectAssignmentFailure$;

  private assignmentRejectionSuccesToast$;

  private undoRejectSelfAssignment$;

  private undoRejectSelfAssignmentFailure$;

  constructor(
    private assignmentApi: AssignmentApi,
    private actions$: Actions,
    private store: Store,
    private candidateRejectionApi: CandidateRejectionApi,
    private toastService: ToastService,
    private sentryErrorHandler: SentryErrorHandler,
  ) {
    this.init$ = this.createInitEffect();
    this.next$ = this.createNextEffect();
    this.reload$ = this.createReloadEffect();
    this.failure$ = this.createAssignmentFailureEffect();
    this.rejectAssignment$ = this.createRejectAssignmentEffect();
    this.rejectAssignmentFailure$ = this.createRejectAssignmentFailureEffect();
    this.assignmentRejectionSuccesToast$ = this.createAssignmentRejectionSuccessToastEffect();
    this.undoRejectSelfAssignment$ = this.createUndoRejectSelfAssignmentEffect();
    this.undoRejectSelfAssignmentFailure$ = this.createUndoRejectSelfAssignmentFailureEffect();
  }

  private createInitEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.initSelfAssignments),
        withLatestFrom(this.store.pipe(select(AssignmentSelectors.selectHasChunksLoad), startWith(false))),
        filter(([_, loaded]) => (loaded === false)),
        map(() => assignmentsActions.nextSelfAssignmentsChunk()),
      ),
    );
  }

  private createNextEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.nextSelfAssignmentsChunk),
        withLatestFrom(this.store.pipe(select(AssignmentSelectors.selectLastChunkId))),
        mergeMap(([_, lastPage]) => {
          return this.assignmentApi.getSelfAssignments((lastPage ?? 0) + 1).pipe(
            map(response => [response, lastPage] as [Hydra<Assignment>, number]),
          );
        }),
        map(([response, lastPage]) => assignmentsActions.nextSelfAssignmentsChunkSuccess({
          assignments: response.toArray(),
          total: response.totalItems,
          chunkId: lastPage + 1,
        })),
        catchError(error => of(assignmentsActions.nextSelfAssignmentsChunkFailure({ error: error }))),
      ),
    );
  }

  private createReloadEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.reloadSelfAssignments),
        map(() => assignmentsActions.nextSelfAssignmentsChunk()),
      ),
    );
  }

  private createAssignmentFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.nextSelfAssignmentsChunkFailure),
        tap((action) => {
          this.sentryErrorHandler.captureError(action.error).then(() => this.toastService.error(
            //eslint-disable-next-line max-len
            'Er is iets mis gegaan bij het ophalen van de opdrachten',
            3000,
            undefined,
            undefined,
            [{ icon: 'close', role: 'cancel' }],
          ));
        }),
      ), { dispatch: false });
  }

  private createRejectAssignmentEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(assignmentsActions.rejectSelfAssignment),
      withLatestFrom(this.store.pipe(
        select(DeclineReasonsSelectors.selectNotInterested),
        isDefined(),
      )),
      mergeMap(([{ assignment }, declineReason]) => {
        return this.candidateRejectionApi.postCandidateRejection({
          declineReason: declineReason,
          slots: assignment.assignmentHasSlots.map(item => item.slot.id),
        }).pipe(map(candidateRejections => [assignment, candidateRejections] as [Assignment, CandidateRejection[]]));
      }),
      map(([assignment, candidateRejections]) => assignmentsActions.rejectSelfAssignmentSuccess({
        assignment: assignment,
        candidateRejections: candidateRejections,
      })),
      catchError(error => of(assignmentsActions.rejectSelfAssignmentFailure({ error: error }))),
    ));
  }

  private createAssignmentRejectionSuccessToastEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(assignmentsActions.rejectSelfAssignmentSuccess),
      tap(() => {
        return this.toastService.create(
          //eslint-disable-next-line max-len
          'Opdracht afgewezen',
          6000,
          undefined,
          undefined,
          undefined,
          [
            {
              text: 'Ongedaan maken', role: 'undo', handler: (): void => {
                this.store.dispatch(assignmentsActions.undoLastSelfAssignmentRejection());
              },
            },
          ],
        );
      }),
    ), { dispatch: false });
  }

  private createRejectAssignmentFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.rejectSelfAssignmentFailure),
        tap((action) => {
          this.sentryErrorHandler.captureError(action.error).then(() => this.toastService.error(
            //eslint-disable-next-line max-len
            'Het afwijzen van deze opdracht is mislukt.',
            3000,
            undefined,
            undefined,
            [{ icon: 'close', role: 'cancel' }],
          ));
        }),
      ), { dispatch: false });
  }

  private createUndoRejectSelfAssignmentEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(assignmentsActions.undoLastSelfAssignmentRejection),
      withLatestFrom(this.store.pipe(select(DeclineReasonsSelectors.selectLastCandidateRejections))),
      concatMap(([_, candidateRejections]) => {
        if (candidateRejections && candidateRejections.length === 0) {
          return VOID;
        }

        return forkJoin((candidateRejections as CandidateRejection[]).map(candidateRejection => {
          return this.candidateRejectionApi.deleteCandidateRejection(candidateRejection.getId());
        }));
      }),
      catchError(error => of(assignmentsActions.undoLastSelfAssignmentRejectionFailure({ error: error }))),
      map(() => {
        return assignmentsActions.undoLastSelfAssignmentRejectionSuccess();
      }),
    ));
  }

  private createUndoRejectSelfAssignmentFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.undoLastSelfAssignmentRejectionFailure),
        tap((action) => {
          this.sentryErrorHandler.captureError(action.error).then(() => this.toastService.error(
            //eslint-disable-next-line max-len
            'Het terug draaien van de afwijzing is mislukt.',
            3000,
            undefined,
            undefined,
            [{ icon: 'close', role: 'cancel' }],
          ));
        }),
      ), { dispatch: false });
  }
}
