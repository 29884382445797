import {
  Component,
  ContentChildren,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit, Optional,
  QueryList,
} from '@angular/core';
import { GoogleMap, MapAnchorPoint, MapInfoWindow } from '@angular/google-maps';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { createHtmlMarker, HTMLMarkerOptions } from './html-marker/html-marker';
import { HtmlMarkerInterface } from './html-marker/html-marker.interface';

type GooglePosition = google.maps.LatLngLiteral | google.maps.LatLng | undefined;

/**
 * Angular component that renders any HTML element via the Google Maps JavaScript API on
 * the interactive layer of Google Maps. This directive does not output any events; To listen
 * to events use `(click)`, `@HostListener()`, or `host` attribute of component definition.
 *
 * **Warning:** Creating HTML marker is more expensive than using `<map-marker>`.
 * {@see https://github.com/angular/components/blob/master/src/google-maps/map-marker/README.md} for information on
 * what map-marker does and how to use it.
 * ```html
 * <google-map>
 *   <div map-html-marker [position]="{lat: 3, lng: 4}" (click)="markerClicked($event)"></div>
 * </google-map>
 * ```
 *
 * Implemented according to
 * @see developers.google.com/maps/documentation/javascript/customoverlays
 */
@Component({
  selector: 'tt-map-html-marker',
  exportAs: 'MapHTMLMarker',
  templateUrl: './tt-map-html-marker.component.html',
  styleUrls: ['./tt-map-html-marker.component.scss'],
})
export class TtMapHtmlMarkerComponent implements OnInit, OnDestroy {

  /**
   * QueryList containing all `<map-info-window>` object within the html scope of this
   * directive. The HtmlMarkerDirective support only one info-window. When multiple are
   * added only the first found InfoWindow is used.
   */
  @ContentChildren(MapInfoWindow) public infoWindowList: QueryList<MapInfoWindow> = new QueryList<MapInfoWindow>();

  /**
   * Setter for {@see self.position$}
   * @param position
   */
  @Input() public set position(position: google.maps.LatLngLiteral | google.maps.LatLng) {
    this.position$.next(position);
  }

  /**
   * Return true if the info window is open.
   */
  private infoWindowOpen: boolean = false;

  /**
   * Observable containing the HtmlElement which is the Html of the Marker.
   */
  private content$!: Observable<HTMLElement>;

  /**
   * The is the HtmlMarker which is created of the content of the directive.
   * HTMLMarker is a extend of {@see google.maps.OverlayView} Which means that this
   * instance can be given to the google maps api to be position on the map.
   */
  public marker?: google.maps.OverlayView & MapAnchorPoint & HtmlMarkerInterface;

  /**
   * Subject containing the Current Position of the HtmlMarker.
   *
   * The Position here is not a x,y pixel position by a Latitude/Longitude position of either a
   * {@see google.maps.LatLngLiteral} or {@see google.maps.LatLng} object.
   */
  private readonly position$: BehaviorSubject<GooglePosition> = new BehaviorSubject<GooglePosition>(undefined);

  /**
   * Subject which is used to complete all hot observables when this instance is destroyed.
   */
  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    @Optional() private readonly googleMap: GoogleMap,
    private ngZone: NgZone,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    const parentElement: HTMLElement = this.elementRef.nativeElement.parentElement as HTMLElement;
    this.content$ = of(this.elementRef.nativeElement);

    if (parentElement && this.elementRef.nativeElement) {
      this.onDestroy.subscribe(() => {
        parentElement.append(this.elementRef.nativeElement);
      });
    }

    if (this.googleMap._isBrowser) {
      this.createOptionsObservable().pipe(take(1)).subscribe(options => {
        // Create the object outside the zone so its events don't trigger change detection.
        // We'll bring it back in inside the `MapEventManager` only for the events that the
        // user has subscribed to.
        this.ngZone.runOutsideAngular(() => this.marker = createHtmlMarker(options));
        //eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        this.marker?.setMap(this.googleMap.googleMap as google.maps.Map);
      });

      this.createWatchForPositionChangesSubscriber();
    }
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    if (this.marker) {
      this.marker.setMap(null as google.maps.Map|null|google.maps.StreetViewPanorama);
    }
  }

  /**
   * This method opens or closes the InfoWindow if there is one available.
   */
  public toggleInfoWindow(): void {
    if (this.infoWindowList.first) {
      if (this.infoWindowOpen) {
        this.infoWindowOpen = false;
        this.infoWindowList.first.close();
        return;
      }
      this.infoWindowOpen = true;
      this.infoWindowList.first.open(this.marker as MapAnchorPoint);
    }
  }

  /**
   * combines {@see self.position} with the created content element.
   */
  private createOptionsObservable(): Observable<HTMLMarkerOptions> {
    return combineLatest([
      this.content$,
      this.position$.pipe(
        filter((position): position is google.maps.LatLng => !!position),
      ),
    ]).pipe(
      map(([content, position]): HTMLMarkerOptions => ({ content: content, position: position } as HTMLMarkerOptions)),
    );
  }

  /**
   * Create a observer that watches the {@see self.position$} observable and passes
   * the emits through to the marker instance.
   */
  private createWatchForPositionChangesSubscriber(): void {
    this.position$.pipe(takeUntil(this.onDestroy)).subscribe(position => {
      if (this.marker && position) {
        this.marker.setPosition(position);
      }
    });
  }

}
