import { Expose } from 'class-transformer';

/**
 * Named it subjectModel because subject matches to much with Rxjs Subject classes
 */
export class SubjectModel {
  /**
   * The unique Identifier of the Subject.
   */
  @Expose() public id!: string;

  /**
   * Name of the subject
   */
  @Expose() public name!: string;

  /**
   * sort version of the {@see SubjectModel.name}
   */
  @Expose() public abbreviation!: string;

  public toString(): string {
    return this.name;
  }
}
