import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { CandidateRejection, DeclineReason } from '@tutor-app/models';
import { declineReasonAdapter, declineReasonFeatureKey, DeclineReasonState } from './decline-reasons.reducer';

type Selector<T> = MemoizedSelector<object, T, DefaultProjectorFn<T>>;

export class DeclineReasonsSelectors {
  //eslint-disable-next-line max-len
  public static readonly getDeclineReasonState: MemoizedSelector<object, DeclineReasonState> = createFeatureSelector<DeclineReasonState>(
    declineReasonFeatureKey,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoading: Selector<boolean> = createSelector(
    DeclineReasonsSelectors.getDeclineReasonState,
    (state) => state.loading,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoaded: Selector<boolean> = createSelector(
    DeclineReasonsSelectors.getDeclineReasonState,
    (state) => state.loaded,
  );

  public static readonly selectError = createSelector(
    DeclineReasonsSelectors.getDeclineReasonState,
    (state) => state.error,
  );

  //eslint-disable-next-line max-len
  public static readonly selectAll: Selector<DeclineReason[]> = createSelector(
    DeclineReasonsSelectors.getDeclineReasonState,
    (state) => {
      return declineReasonAdapter.getSelectors().selectAll(state);
    },
  );

  //eslint-disable-next-line max-len
  public static readonly selectNotInterested = createSelector(
    DeclineReasonsSelectors.selectAll,
    (reasons) => {
      if (!reasons || reasons.length === 0) {
        return undefined;
      }
      return reasons.find(reason => reason.description === 'Niet (meer) geïnteresseerd in dienst');
    },
  );

  public static readonly selectLastCandidateRejections: Selector<CandidateRejection[] | null> = createSelector(
    DeclineReasonsSelectors.getDeclineReasonState,
    (state) => state.lastCandidateRejections,
  );
}
