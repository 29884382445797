import { LevelEnum } from '@tutor-app/enums';
import { Expose, Type } from 'class-transformer';
import { SubjectModel } from '../subject.model';
import { LessonMinimal } from './lesson-minimal.model';

/**
 * The standard version of the Lesson Resource.
 */
export class Lesson extends LessonMinimal {

  /**
   * The possible level on which the Lesson is given.
   */
  @Expose() public level?: LevelEnum;

  /**
   * The possible subject covert in the lesson.
   */
  @Type(() => SubjectModel)
  @Expose() public subject?: SubjectModel;

  @Expose() public isOnline!: boolean;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    if (this.name) {
      return this.name;
    }

    let text: string = this.period?.humanReadableString() ?? '';

    text += (this.subject) ? ` - ${this.subject}` : '';
    text += (this.level) ? ` - ${this.level}` : '';

    return text;
  }
}
