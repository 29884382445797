import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { environment } from '@tutor-app/environments';
import { DeclineReason } from '@tutor-app/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetDeclineReasonResponse } from './decline-reason.response';

export interface DeclineReasonApiInterface {
  getDeclineReasons(): Observable<Hydra<DeclineReason>>;
}

@Injectable({
  providedIn: 'root',
})
export class DeclineReasonApi implements DeclineReasonApiInterface {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get the decline reasons from the backend.
   */
  public getDeclineReasons(): Observable<Hydra<DeclineReason>> {
    const url: string = `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v3/decline_reasons`;

    return this.httpClient.get<Collection<GetDeclineReasonResponse>>(url).pipe(
      map(response => denormalize(DeclineReason, response)),
    );
  }
}
