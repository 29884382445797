import { TsRange } from '@techniek-team/class-transformer';
import { AssignmentStateEnum, LevelEnum } from '@tutor-app/enums';
import { Exclude, Expose, Type } from 'class-transformer';
import { isFuture, min } from 'date-fns';
import { AssignmentHasSlotDetailed } from './assignment-has-slot/assignment-has-slot-detailed.model';
import { AssignmentMinimal } from './assignment-minimal.model';
import { BusinessService } from '../business/business-service.model';
import { LocationModelOld } from '../location/locationModelOld';
import { Role } from '../role.model';
import { ScheduleMinimal } from '../schedule/schedule-minimal.model';
import { SubjectModel } from '../subject.model';

/**
 * standard version of the Assignment resource from Scheduler-api
 */
export class Assignment extends AssignmentMinimal {

  /**
   * The name of the assignment. This is used when the assignment doesn't have
   * a subject. So for example a `examentraining` is show as
   *
   * @example
   * ExamenTraining
   * Wiskunde
   *
   * Where as a `Onderwijshelden` assignment could be
   *
   * @example
   * Onderwijshelden
   * Lesopvang basisonderwijs
   */
  @Expose() public name!: string;

  /**
   * Time precision specifies who precise the actual workings time should be set
   *
   * The is only used within the Tutor-app
   * @example
   * '0,15,30,45'
   */
  @Expose() public timePrecision!: number;

  /**
   * If true the candidate is allowed to approved the assignment himself.
   *
   * Approving means that he can set the actual worked times and move the
   * assigment to the approved state.
   */
  @Expose() public allowApproveByCandidate!: boolean;

  @Expose() public isUrgent!: boolean;

  @Type(() => Date)
  @Expose() public definitiveConfirmationDate!: Date;

  @Expose() public allowSetActualTimePeriodByCandidate: boolean = false;

  /**
   * This is the schedule of the assignment.
   */
  @Type(() => ScheduleMinimal)
  @Expose() public schedule?: ScheduleMinimal;

  /**
   * The location where the assignment takes place.
   *
   * This property can be empty in which case the assignment takes place online.
   */
  @Type(() => LocationModelOld)
  @Expose() public location?: LocationModelOld;

  /**
   * The service done in this assignment. For example `Examen Training` or `Bijles`
   */
  @Type(() => BusinessService)
  @Expose() public businessService!: BusinessService;

  /**
   * A list of all the slots or shift within the assignment. Each slot contains
   * a start/end time for when it takes place.
   */
  @Type(() => AssignmentHasSlotDetailed)
  @Expose() public assignmentHasSlots!: AssignmentHasSlotDetailed[];

  /**
   * Return either the actual time periods which can either be the actual time period
   * or the slot time period if the actual isn't set yet.
   */
  @Exclude()
  public get getCorrectTimePeriods(): TsRange[] {
    return [
      ...this.assignmentHasSlots.map(assignmentHasSlots => {
        return assignmentHasSlots.realTimePeriod;
      }),
    ];
  }

  /**
   * Returns the color corresponding with the state of the assignment.
   * When the assignment is unassigned we don't show a color.
   */
  public get cardStateColor(): string {
    switch (this.state) {
      case AssignmentStateEnum.PROVISIONALLY_CONFIRMED:
      case AssignmentStateEnum.CONFIRMED:
        return 'confirmed';
      case AssignmentStateEnum.FINAL:
        return 'finalized';
      case AssignmentStateEnum.WAITING_FOR_CONFIRMATION:
        return 'unconfirmed';
      case AssignmentStateEnum.DRAFT:
        return 'unconfirmed';
    }
    return '';
  }

  public get isOnline(): boolean {
    return this.assignmentHasSlots[0].slot.lesson.isOnline;
  }

  /**
   * An assignment holds a number of slots each of which can have a different
   * Role Requirement. This method returns a distinct list of all roles,
   * required for this assignment.
   */
  public get roles(): Role[] {
    return [...new Map<string, Role>(this.assignmentHasSlots.map(assignmentSlot => [
      assignmentSlot.role.id,
      assignmentSlot.role,
    ])).values()];
  }

  /**
   * An assignment holds a number of slots each of which can be about a different
   * Subject. This method returns a distinct list of all subjects, given
   * in this assignment.
   */
  public get subjects(): SubjectModel[] {
    const distinctSubjects = new Map<string, SubjectModel>(this.assignmentHasSlots
      .filter(assignmentHasSlot => assignmentHasSlot.subject)
      .map(assignmentSlot => {
        return [assignmentSlot.subject?.id as string, assignmentSlot.subject] as [string, SubjectModel];
      }));

    return [...distinctSubjects.values()];
  }

  /**
   * Returns true if the assignment contains a subject.
   */
  public get hasSubject(): boolean {
    return this.subjects.length > 0;
  }

  /**
   * Returns true if the assignment contains a subject.
   */
  public get hasSlotWithoutSettedWorkingTime(): boolean {
    return this.assignmentHasSlots.filter(hasSlot => !hasSlot.actualTimePeriodUpdatedAt).length > 0;
  }

  /**
   * returns a string separated by a / of all subject. {@see Assignment.subjects}
   * for more information.
   */
  public get subjectLabel(): string {
    return this.subjects.map(subject => subject.name).join(' / ');
  }

  /**
   * An assignment holds a number of slots each of which can be given a different
   * Level. This method returns a distinct list of the levels, on which this
   * assignment is given.
   */
  public get levels(): LevelEnum[] {
    const distinctLevels = new Set<LevelEnum>(
      this.assignmentHasSlots
        .filter(assignmentHasSlot => !!assignmentHasSlot.level)
        .map(assignmentSlot => assignmentSlot.level) as LevelEnum[],
    );

    return [...distinctLevels.values()];
  }

  /**
   * Return true if the first start time of any slot lies in the past.
   */
  public isPast(): boolean {
    for (let slot of this.assignmentHasSlots) {
      if (isFuture(slot.endDate)) {
        return false;
      }
    }
    return true;
  }

  public get firstStartDate(): Date {
    return min(this.assignmentHasSlots.map(slot => slot.realTimePeriod.start));
  }
}
