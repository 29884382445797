import { Expose } from 'class-transformer';

/**
 * Detailed version of the Region resource from Scheduler-api
 */
export class Region {
  /**
   * The unique Identifier of The Resource
   */
  @Expose() public id!: string;

  /**
   * The abbreviation or sort version of the {@see name}
   */
  @Expose() public abbr!: string;

  /**
   * Name of this Resource
   */
  @Expose() public name!: string;

  /**
   * Returns true if this region is a province of the netherlands.
   */
  @Expose() public isProvince!: boolean;

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.name;
  }
}
