export function isValidBsn(value: string | number | null | undefined): boolean {
  if (!value) {
    return false;
  }

  let stringValue: string = value.toString();

  // First check if we have an 8 or 9-digit number
  if (!/^[0-9]{8,9}$/.test(stringValue)) {
    return false;
  }

  // Pad 8 digit value with a zero to the left
  if (stringValue.length === 8) {
    stringValue = '0' + stringValue;
  }

  const factors: number[] = [9, 8, 7, 6, 5, 4, 3, 2, -1];

  const digits: string[] = stringValue.split('');
  let checksum: number = 0;

  for (let i: number = 0; i < 9; i++) {
    checksum += parseInt(digits[i], 10) * factors[i];
  }

  return checksum % 11 === 0;
}
