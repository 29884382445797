import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GoogleMapLibraryEnum, TTGoogleMapsModule } from '@techniek-team/google-maps';
import { LyceoUser, UserService } from '@techniek-team/oauth';
import { AuthModule } from '@techniek-team/oauth-capacitor';
import { SentryCapacitorConfig, SentryCapacitorModule } from '@techniek-team/sentry-capacitor';
import { SentryEnvironment, SentryErrorHandler, SentryWebConfig } from '@techniek-team/sentry-web';
import { environment } from '@tutor-app/environments';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MenuLayoutPageModule } from './menu-layout/menu-layout.module';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      backButtonIcon: 'arrow-back',
      backButtonText: '',
    }),
    IonicStorageModule.forRoot({
      name: '__tutorapp-web-app',
      driverOrder: environment.production ? [Drivers.IndexedDB, Drivers.LocalStorage] : [Drivers.LocalStorage],
    }),
    SentryCapacitorModule.forRoot({
      environment: environment.environment,
      dsn: 'https://80c1b7d82ad14b89ad83be74252766b9@errors.techniek-team.nl//24',
      release: environment.release,
      userService: UserService,
      autoSessionTracking: false,
    }),
    AuthModule.forRoot({
      clientId: environment.ssoConfig.clientId,
      ssoBaseUrl: environment.ssoConfig.ssoBaseUrl,
      profileBaseUrl: environment.ssoConfig.profileBaseUrl,
      baseUrl: environment.ssoConfig.baseUrl,
      redirectUri: environment.ssoConfig.redirectUri,
      loginUrl: '/login',
      homeUrl: '/home',
      whitelist: ['https://werkenbijapp.lyceo.nl/assets/notices.json'],
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      model: LyceoUser,
    }),
    TTGoogleMapsModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: [
        GoogleMapLibraryEnum.GEOMETRY,
      ],
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.environment !== SentryEnvironment.LOCAL, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MenuLayoutPageModule,
    AppRoutingModule,
    CoreModule,
    HammerModule,
  ],
  providers: [
    // Only provider boostrap services here you can use the core module to
    // provide app services
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: SentryWebConfig, useExisting: SentryCapacitorConfig },
    { provide: ErrorHandler, useExisting: SentryErrorHandler },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
