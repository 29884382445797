import { Expose } from 'class-transformer';

/**
 * standard version of the Role resource from Scheduler-api.
 */
export class Role {
  /**
   * The unique Identifier of the Role.
   */
  @Expose() public id!: string;

  /**
   * The abbreviation or sort version of the {@see name}.
   */
  @Expose() public abbr!: string;

  /**
   * Name of this Role.
   */
  @Expose() public name!: string;

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.name;
  }
}
