import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CandidateRejection, DeclineReason } from '@tutor-app/models';

// Compiler will infer the type
//eslint-disable-next-line @typescript-eslint/typedef
export const declineReasonActions = createActionGroup({
  source: 'DeclineReason/API',
  events: {
    'Init Decline Reasons': emptyProps(),
    'Load Decline Reasons': emptyProps(),
    'Reload Decline Reasons': emptyProps(),
    'Load Decline Reasons Success': props<{ declineReasons: DeclineReason[] }>(),
    'Load Decline Reasons Failure': props<{ error: Error }>(),
    'Set Last Candidate Rejections': props<{ ids: CandidateRejection[] | null }>(),
  },
});
