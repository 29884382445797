import { Injectable } from '@angular/core';
import { RefresherCustomEvent } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { PersonDetailed } from '@tutor-app/models';
import { Observable, shareReplay } from 'rxjs';
import { ChangePersonRequest } from '../../../api/hrm/person/person.request';
import { usersActions } from './user.actions';
import { UserSelectors } from './user.selectors';

@Injectable()
export class UserStoreService {
  public loading$: Observable<boolean> = this.store.pipe(select(UserSelectors.selectLoading), shareReplay(1));

  public loaded$: Observable<boolean> = this.store.pipe(select(UserSelectors.selectLoaded), shareReplay(1));

  public user$: Observable<PersonDetailed> = this.store.pipe(
    select(UserSelectors.selectUser),
    isDefined(),
    shareReplay(1),
  );

  //eslint-disable-next-line max-len
  public loadingAvatar$: Observable<boolean> = this.store.pipe(select(UserSelectors.selectLoadingAvatar), shareReplay(1));

  public showHomeAvailabilityTip$: Observable<boolean> = this.store.pipe(select(
    UserSelectors.selectShowHomeAvailabilityTip),
  );

  public onBoarding$: Observable<boolean> = this.store.pipe(select(UserSelectors.selectOnBoarding));


  constructor(
    private readonly store: Store,
  ) {
    this.store.dispatch(usersActions.initUserHomeAvailabilityTip());
    this.store.dispatch(usersActions.initUserOnBoarding());
  }

  public init(): void {
    this.store.dispatch(usersActions.initUser());
  }

  public reloadUser(refreshEvent: RefresherCustomEvent): void {
    this.store.dispatch(usersActions.reloadUser({ refreshEvent: refreshEvent }));
  }

  public updateUser(request: ChangePersonRequest): void {
    this.store.dispatch(usersActions.updateUser({ request: request }));
  }

  public postAvatar(file: Blob): void {
    this.store.dispatch(usersActions.postAvatar({ file: file }));
  }

  public cameraFailure(error: unknown): void {
    this.store.dispatch(usersActions.postAvatarFailure({ error: error }));
  }

  public deleteAvatar(): void {
    this.store.dispatch(usersActions.deleteAvatar());
  }

  public hideHomeAvailabilityTip(): void {
    this.store.dispatch(usersActions.setUserHomeAvailabilityTip({ value: false }));
  }
}
