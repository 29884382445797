import { Expose } from 'class-transformer';

/**
 * standard version of the BusinessEntity resource from Scheduler-api.
 */
export class BusinessEntity {
  /**
   * The unique Identifier for the company.
   */
  @Expose() public id!: string;

  /**
   * Name of the company.
   */
  @Expose() public name!: string;

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.name;
  }
}
