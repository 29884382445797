import { SentryEnvironment } from '@techniek-team/sentry-web';
import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  environment: SentryEnvironment.PRODUCTION,
  release: '<<TT_RELEASE>>',
  schedulerApi: {
    url: 'https://roostertool.mijnskoleo.nl',
    iri: '/api',
  },
  hrmApi: {
    url: 'https://perza.lyceo.nl',
    iri: '/api',
  },
  academyApi: {
    url: 'https://bieb.lyceo.nl',
    iri: '/api',
  },
  ssoConfig: {
    clientId: '84302e16-3c89-4c5d-83dd-bda8f4537bdc',
    ssoBaseUrl: 'https://sso.lyceo.nl',
    iri: '/api',
    profileBaseUrl: 'https://sso.lyceo.nl/api/v1/profile',
    baseUrl: '<<TT_BASE_URL>>', //'https://werkenbijapp.lyceo.nl',
    redirectUri: '<<TT_REDIRECT_URL>>', //'https://werkenbijapp.lyceo.nl/',
  },
  tutorAppBrand: 'lyceo',
  googleMapsApiKey: 'AIzaSyC5rl-cH5_vQmpsxsyHOM9sMqWaxvUm_N4',
};
