<ion-tabs>
  <ion-tab-bar slot="bottom" id="root-ion-tabs">
    <ion-tab-button (click)="closeAllOverlays()"
                    tab="home">
      <fa-icon [icon]="['fas', 'home']"></fa-icon>
      <ion-label>Home</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()"
                    tab="agenda">
      <fa-icon [icon]="['fas', 'calendar-days']"></fa-icon>
      <ion-label>Agenda</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()"
                    tab="opdrachten">
      <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>
      <ion-label>Diensten</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()"
                    tab="uitbetalingen">
      <fa-icon [icon]="['fas', 'money-bill']"></fa-icon>
      <ion-label>Uitbetalingen</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()"
                    tab="mijn-gegevens">
      <fa-icon [icon]="['fas', 'user']"></fa-icon>
      <ion-label>Profiel</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
