import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DeclineReasonsEffects } from './+state/decline-reasons.effects';
import { declineReasonFeatureKey, declineReasonsReducer } from './+state/decline-reasons.reducer';
import { DeclineReasonsStoreService } from './decline-reasons-store.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(declineReasonFeatureKey, declineReasonsReducer),
    EffectsModule.forFeature([DeclineReasonsEffects]),
  ],
  providers: [
    DeclineReasonsStoreService,
  ],
})
export class DataAccessDeclineReasonsModule {
}
