import { createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { PersonDetailed } from '@tutor-app/models';
import { usersActions } from './user.actions';

export const userFeatureKey: string = 'user';

export interface UserState {
  loaded: boolean;

  loading: boolean;

  loadingAvatar: boolean;

  user: PersonDetailed | null;

  error?: Error | null;
  userOnBoarding: boolean;
  showHomeAvailabilityTip: boolean;
}
export const initialState: UserState = {
  loaded: false,
  loading: false,
  loadingAvatar: false,
  error: null,
  user: null,
  userOnBoarding: true,
  showHomeAvailabilityTip: true,
};

export const userReducer: ActionReducer<UserState> = createReducer(
  initialState,
  on(usersActions.initUser, state => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(usersActions.reloadUser, state => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(usersActions.loadUserSuccess, (state, { user }) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      user: user,
    };
  }),
  on(usersActions.loadUserFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(usersActions.reloadUserSuccess, (state, { user }) => {
    return {
      ...state,
      loading: false,
      user: user,
    };
  }),
  on(usersActions.reloadUserFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(usersActions.updateUserSuccess, (state, { user }) => {
    return {
      ...state,
      user: user,
    };
  }),
  on(usersActions.postAvatar, state => {
    return {
      ...state,
      loadingAvatar: true,
    };
  }),
  on(usersActions.postAvatarSuccess, state => {
    return {
      ...state,
      loadingAvatar: false,
    };
  }),
  on(usersActions.postAvatarFailure, state => {
    return {
      ...state,
      loadingAvatar: false,
    };
  }),
  on(usersActions.setUserOnBoardingSuccess, (state, { value }) => ({
    ...state,
    userOnBoarding: value,
  })),
  on(usersActions.setUserOnBoardingFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(usersActions.setUserHomeAvailabilityTipSuccess, (state, { value }) => ({
    ...state,
    showHomeAvailabilityTip: value,
  }),
  ),
  on(usersActions.setUserHomeAvailabilityTipFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
