import { denormalize } from '@techniek-team/class-transformer';
import { DocumentTypeEnum } from '@tutor-app/enums';
import { Exclude, Expose, Type } from 'class-transformer';
import { AssignmentDocument } from './assignment-document/assignment-document.model';
import { AssignmentOnlineLesson } from './assignment-online-lesson/assignment-online-lesson.model';
import { Assignment } from './assignment.model';
import { LocationDetailed } from '../location/location-detailed.model';

interface AssignmentHasDocuments {
  id: string;
  document: {
    id: string;
    publicationStart: string;
    title: string;
    type: DocumentTypeEnum;
    url?: string;
    signedUrl?: string;
  };
}

/**
 * The Detailed version of {@see Assignment} model.
 */
export class AssignmentDetail extends Assignment {

  /**
   * The grade that is given to the candidate for this assignment.
   */
  @Expose() public grade?: number;

  /**
   * Number of people that have given a grade for this assignment.
   */
  @Expose() public gradeCount?: number;

  /**
   * The location where the assignment takes place.
   *
   * This property can be empty in which case the assignment takes place online.
   * This property is overloaded from {@see Assignment.location} since
   * AssignmentDetail contains more Location details.
   */
  @Type(() => LocationDetailed)
  @Expose() public override location?: LocationDetailed;

  /**
   * List of document that are related to the assignment.
   *
   * The list a made available to the candidate in the {@see AssignmentDetailPage}
   * Where he/she can go to the link or download the file.
   */
  @Type(() => AssignmentDocument)
  @Exclude() public documents!: AssignmentDocument[];

  /**
   * Setter to convert assignmentHasDocuments to {@see AssignmentDocument} that are
   * stored in the {@see self.documents} property
   *
   * The actual endpoint returns a `assignmentHasDocuments` property which isn't
   * usefully within the app because it doesn't contain usefully additional information
   * so we convert the AssignmentHasDocuments property to a {@see AssignmentDocument}
   * model.
   * @param documents
   */
  @Expose()
  public set assignmentHasDocuments(
    documents: AssignmentHasDocuments[],
  ) {
    this.documents = denormalize(AssignmentDocument, documents.map(item => ({
      ...item.document,
      assignmentHasDocumentId: item.id,
    })));
  }

  /**
   * When an assignment takes place online the assignment can contain a number of
   * url to the online WizIq rooms.
   */
  @Type(() => AssignmentOnlineLesson)
  @Expose({ name: 'onlineLessonLinks' }) public onlineLessons!: AssignmentOnlineLesson[];

  /**
   * Returns true if the assignment has online lessons.
   */
  public get hasOnlineLessons(): boolean {
    return this.onlineLessons.length > 0;
  }

  /**
   * Returns true if the assignment contains documents.
   */
  public get hasDocuments(): boolean {
    return this.documents.length > 0;
  }

  /**
   * Returns true if the assignment contains a small amount of documents.
   * In this case we want to show them in a different way.
   */
  public get hasLargeAmountOfDocuments(): boolean {
    return this.documents.length > 3;
  }
}
