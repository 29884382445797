import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserService } from '@techniek-team/oauth';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { of, tap, withLatestFrom } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { DeclineReasonApi } from './api/decline-reason/decline-reason.api';
import { declineReasonActions } from './decline-reasons.actions';
import { DeclineReasonsSelectors } from './decline-reasons.selectors';

@Injectable()
export class DeclineReasonsEffects {
  private initDeclineReasons$;

  private reloadDeclineReasons$;

  private loadDeclineReasons$;

  private declineReasonsFailure$;

  constructor(
    private declineReasonApi: DeclineReasonApi,
    private actions$: Actions,
    private toastService: ToastService,
    private userService: UserService,
    private sentryErrorHandler: SentryErrorHandler,
    private store: Store,
  ) {
    this.initDeclineReasons$ = this.createInitDeclineReasonsEffect();
    this.reloadDeclineReasons$ = this.createReloadDeclineReasonsEffect();
    this.loadDeclineReasons$ = this.createLoadDeclineReasonsEffect();
    this.declineReasonsFailure$ = this.createDeclineReasonsFailureEffect();
  }

  private createInitDeclineReasonsEffect() {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(declineReasonActions.initDeclineReasons),
        withLatestFrom(
          this.store.pipe(select(DeclineReasonsSelectors.selectLoaded)),
        ),
        filter(([_, loaded]) => loaded === false),
        map(() => declineReasonActions.loadDeclineReasons()),
      ),
    );
  }

  private createReloadDeclineReasonsEffect() {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(declineReasonActions.reloadDeclineReasons),
        map(() => declineReasonActions.loadDeclineReasons()),
      ),
    );
  }

  private createLoadDeclineReasonsEffect() {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(declineReasonActions.loadDeclineReasons),
        switchMap(() => this.userService.currentUser()),
        isDefined(),
        mergeMap(() => {
          return this.declineReasonApi.getDeclineReasons().pipe(
            map((response) =>
              declineReasonActions.loadDeclineReasonsSuccess({
                declineReasons: response.toArray(),
              }),
            ),
            catchError((error) =>
              of(
                declineReasonActions.loadDeclineReasonsFailure({ error: error }),
              ),
            ),
          );
        }),
      ),
    );
  }

  private createDeclineReasonsFailureEffect() {
    return createEffect(
      () =>
        this.actions$.pipe(
          ofType(declineReasonActions.loadDeclineReasonsFailure),
          tap((action) => {
            return this.sentryErrorHandler
              .captureError(action.error)
              .then(() =>
                this.toastService.error(
                  'Kan de afwijs redenen niet ophalen het kan zijn dat je beschikbare opdrachten niet kan afwijzen.',
                  3000,
                  undefined,
                  undefined,
                  [{ icon: 'close', role: 'cancel' }],
                ),
              );
          }),
        ),
      { dispatch: false },
    );
  }
}
