import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { normalizeNull } from '@techniek-team/common';
import { UserService } from '@techniek-team/oauth';
import { AssignmentTransitionEnum } from '@tutor-app/enums';
import { environment } from '@tutor-app/environments';
import { Assignment, AssignmentDetail } from '@tutor-app/models';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AssignToAssignmentRequest } from './assignment.request';
import {
  AssignmentTransitionResponse,
  GetAssignmentDetailsResponse,
  GetAssignmentsResponse,
} from './assignment.response';

@Injectable({
  providedIn: 'root',
})
export class AssignmentApi {

  constructor(
    protected httpClient: HttpClient,
    protected userService: UserService,
  ) {
  }

  /**
   * Retrieves a list of all future and past assignments the candidate is assigned to.
   */
  public getAssignments(): Observable<Assignment[]> {
    return this.userService.currentUser().pipe(
      switchMap(user => this.httpClient.get<GetAssignmentsResponse[]>(
        `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v1/candidates/${user.id}/assignments`,
      )),
      map(response => normalizeNull(response)),
      map(response => denormalize(Assignment, response)),
    );
  }

  /**
   * Retrieves a list of all assignments that the candidate can assign himself to.
   */
  public getSelfAssignments(page: number = 1): Observable<Hydra<Assignment>> {
    const params: HttpParams = new HttpParams()
      .set('page', page)
      .set('itemsPerPage', 20);

    const url: string = `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v1/assignments/self-assignable`;
    return this.httpClient.get<Collection<GetAssignmentsResponse>>(url, { params: params }).pipe(
      map(response => denormalize(Assignment, response)),
    );
  }

  /**
   * Returns all details about a specific assignment.
   * @param assignmentId
   */
  public getAssignment(assignmentId: string): Observable<AssignmentDetail> {
    return this.httpClient.get<GetAssignmentDetailsResponse>(
      `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v2/assignments/${assignmentId}`,
    ).pipe(
      map(response => normalizeNull(response)),
      map(response => denormalize(AssignmentDetail, response)),
    );
  }

  /**
   * Accept or decline an assignments
   */
  public applyTransition(
    assignment: Assignment,
    transition: AssignmentTransitionEnum,
  ): Observable<AssignmentTransitionResponse> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v1/assignments/${assignment.getId()}/transitions`;

    return this.httpClient.post<AssignmentTransitionResponse>(url, {
      transitionName: transition,
    });
  }

  public assignToAssignment(assignment: Assignment): Observable<void> {
    return this.userService.currentUser().pipe(switchMap(user => {
      const request: AssignToAssignmentRequest = {
        selfAssign: {
          //eslint-disable-next-line max-len
          uri: `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v1/candidates/${user.id}/assignments`,
          method: 'POST',
          parameters: {
            id: assignment.getId(),
          },
        },
        transition: {
          //eslint-disable-next-line max-len
          uri: `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v1/assignments/${assignment.getId()}/transitions`,
          method: 'POST',
          parameters: {
            transitionName: AssignmentTransitionEnum.CONFIRM,
          },
        },
      };

      return this.httpClient.post<void>(
        `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v1/batch`,
        request,
      );
    }));
  }
}
