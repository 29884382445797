import { JsonLd } from '@techniek-team/class-transformer';
import { DeclineReasonTypeEnum } from '@tutor-app/enums';
import { Expose } from 'class-transformer';

export class DeclineReason extends JsonLd {

  @Expose() public description!: string;

  @Expose() public declineReasonType!: DeclineReasonTypeEnum;
}
