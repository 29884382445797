import { JsonLd } from '@techniek-team/class-transformer';
import { Expose } from 'class-transformer';

/**
 * @deprecated Only use this class for old code
 */
export class TemporarilyJsonLd extends JsonLd {
  @Expose({ name: 'id' }) public id!: string;

  public override getId(): string {
    return this.id;
  }
}
