import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { denormalize } from '@techniek-team/class-transformer';
import { normalizeNull } from '@techniek-team/common';
import { UserService } from '@techniek-team/oauth';
import { environment } from '@tutor-app/environments';
import { JobApplication, PersonDetailed } from '@tutor-app/models';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChangePersonRequest } from './person.request';
import { GetPersonResponse, PersonGetActiveJobApplicationResponse } from './person.response';

@Injectable({
  providedIn: 'root',
})
export class PersonApi {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
  ) {
  }

  /**
   * Get the use data of the user
   */
  public getPerson(personId?: string): Observable<PersonDetailed> {
    let observable: Observable<GetPersonResponse>;
    if (personId) {
      observable = this.httpClient
        .get<GetPersonResponse>(`${environment.hrmApi.url}${environment.hrmApi.iri}/people/${personId}`);
    } else {
      observable = this.userService.currentUser().pipe(
        switchMap(user => this.httpClient.get<GetPersonResponse>(
          `${environment.hrmApi.url}${environment.hrmApi.iri}/people/${user.id}`,
        )),
      );
    }

    return observable.pipe(
      map(response => normalizeNull(response)),
      map(response => denormalize(PersonDetailed, response)),
    );
  }

  /**
   * Save the data of the user
   */
  public updatePerson(request: ChangePersonRequest): Observable<PersonDetailed> {
    const url: string = `${environment.hrmApi.url}${environment.hrmApi.iri}/people/${request.personId}`;

    return this.httpClient.put<GetPersonResponse>(url, {
      hasDriversLicense: request.hasDriversLicense,
      hasCar: request.hasCar,
      iban: request.iban,
      phoneNumber: request.phoneNumber,
      mainAddress: {
        street: request.mainAddress?.street,
        streetNumber: request.mainAddress?.streetNumber,
        streetNumberSuffix: request.mainAddress?.streetNumberSuffix,
        postalCode: request.mainAddress?.postalCode,
        town: request.mainAddress?.town,
      },
      attendsEducationPrograms: request.attendsEducationPrograms.map(value => ({
        '@id': value.id,
        'isMain': value.isMain,
        'yearStart': value.yearStart,
        'yearCompleted': value.yearCompleted,
        'instituteName': value.instituteName,
        'programmeName': value.programmeName,
      })),
    }).pipe(
      map(response => denormalize(PersonDetailed, response)),
    );
  }

  /**
   * Check if the user has any selection events
   */
  public getActiveJobApplication(personId: string): Observable<JobApplication> {
    const url: string = `${environment.hrmApi.url}${environment.hrmApi.iri}/people/${personId}/active_job_application`;
    const headers: HttpHeaders = new HttpHeaders().set('content-type', 'application/ld+json');
    return this.httpClient.get<PersonGetActiveJobApplicationResponse>(
      url,
      { headers: headers },
    ).pipe(
      map(response => normalizeNull(response)),
      map(response => denormalize(JobApplication, response)),
    );
  }

  /**
   * Upload the users avatar
   */
  public postAvatar(personId: string, file: Blob): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const url: string = `${environment.hrmApi.url}${environment.hrmApi.iri}/people/${personId}/profile_photo`;

    return this.httpClient.post<void>(url, formData);
  }

  /**
   * Upload the users avatar
   */
  public deleteAvatar(personId: string): Observable<void> {
    const url: string = `${environment.hrmApi.url}${environment.hrmApi.iri}/people/${personId}/profile_photo`;

    return this.httpClient.delete<void>(url);
  }
}
