import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { CandidateRejection, DeclineReason } from '@tutor-app/models';
import { declineReasonActions } from './decline-reasons.actions';

export const declineReasonFeatureKey: string = 'declineReason';

export interface DeclineReasonState extends EntityState<DeclineReason> {
  loaded: boolean;

  loading: boolean;

  error?: Error | null;

  lastCandidateRejections: CandidateRejection[] | null;
}

export function selectDeclineReason(item: DeclineReason): string {
  return item.getIri() as string;
}

export const declineReasonAdapter: EntityAdapter<DeclineReason> = createEntityAdapter<DeclineReason>({
  selectId: selectDeclineReason,
});

export const initialState: DeclineReasonState = declineReasonAdapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  lastCandidateRejections: null,
});

export const declineReasonsReducer: ActionReducer<DeclineReasonState> = createReducer(
  initialState,
  on(declineReasonActions.loadDeclineReasons, state => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(declineReasonActions.loadDeclineReasonsSuccess, (state, { declineReasons }) => {
    return declineReasonAdapter.setMany(declineReasons, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(declineReasonActions.loadDeclineReasonsFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(declineReasonActions.setLastCandidateRejections, (state, { ids }) => ({
    ...state,
    lastCandidateRejections: ids,
  })),
);
