import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthConfig, BaseAuthModule, InAppBrowserService, UserInterface } from '@techniek-team/oauth';
import { InAppBrowserCapacitorService } from './in-app-browser.capacitor.service';

@NgModule({
  imports: [
    BaseAuthModule,
  ],
  exports: [
    BaseAuthModule,
  ],
})
export class AuthModule {
  public static forRoot<T extends UserInterface>(options: AuthConfig<T>): ModuleWithProviders<AuthModule> {
    options = { ...new AuthConfig(), ...options };

    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: options },
        { provide: InAppBrowserService, useClass: InAppBrowserCapacitorService },
      ],
    };
  }
}
