import { TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { Role } from '../role.model';

/**
 * The bare minimal version of the Slot Resource.
 */
export class SlotMinimal {
  /**
   * The unique Identifier of the Slot.
   */
  @Expose() public id!: string;

  /**
   * Time period when this shift takes place
   */
  @Type(() => TsRange)
  @Expose() public timePeriod!: TsRange;

  /**
   * The role the candidate is preforming during this shift.
   */
  @Expose() public role!: Role;
}
