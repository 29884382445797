import { Expose, Type } from 'class-transformer';
import { BusinessEntity } from './business-entity.model';

/**
 * standard version of the BusinessService resource from Scheduler-api.
 */
export class BusinessService {

  /**
   * The unique Identifier of the business service.
   */
  @Expose() public id!: string;

  /**
   * The name of the service.
   *
   * This is a product(service) our company(s) supplies to customers.
   */
  @Expose() public name!: string;

  /**
   * The company under which we supply this service.
   */
  @Type(() => BusinessEntity)
  @Expose() public businessEntity!: BusinessEntity;

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.name;
  }
}
