import { Expose } from 'class-transformer';

/**
 * The bare minimal version of an Schedule Resource.
 */
export class ScheduleMinimal {
  /**
   * The unique Identifier of The Schedule
   */
  @Expose() public id!: string;

  /**
   * Name of this Schedule
   */
  @Expose() public name!: string;

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.name;
  }
}
