import { Expose } from 'class-transformer';

/**
 * The bare minimal version of an AssignmentHasSlot Resource from Scheduler-api.
 */
export class AssignmentHasSlotMinimal {

  /**
   * The unique Identifier of the AssignmentHasSlot.
   */
  @Expose() public id!: string;
}
