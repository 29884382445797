import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KebabCasePipe } from './kebab-case.pipe';

@NgModule({
  declarations: [
    KebabCasePipe,
  ],
  exports: [
    KebabCasePipe,
  ],
  imports: [
    CommonModule,
  ],
})
export class KebabCaseModule { }
