import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AssignmentDetail } from '@tutor-app/models';
import { compareDesc } from 'date-fns';
import { assignmentAdapter, assignmentFeatureKey, AssignmentState } from './assignment.reducer';

export class AssignmentSelectors {
  //eslint-disable-next-line max-len
  public static readonly getAssignmentState: MemoizedSelector<object, AssignmentState> = createFeatureSelector<AssignmentState>(
    assignmentFeatureKey,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoading = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.loading,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoaded = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.loaded,
  );

  public static readonly selectError = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.error,
  );

  //eslint-disable-next-line max-len
  public static readonly selectAll = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => assignmentAdapter.getSelectors().selectAll(state),
  );

  //eslint-disable-next-line max-len
  public static readonly selectHistory = createSelector(
    AssignmentSelectors.selectAll,
    (assignments) => {
      return assignments
        .filter(assignment => assignment.isPast())
        .sort((a, b) => compareDesc(a.firstStartDate, b.firstStartDate));
    },
  );

  //eslint-disable-next-line max-len
  public static readonly selectPlanned = createSelector(
    AssignmentSelectors.selectAll,
    (assignments) => {
      return assignments
        .filter(assignment => !assignment.isPast() && !assignment.isUnassigned());
    },
  );

  //eslint-disable-next-line max-len
  public static readonly getAssignmentEntities = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => assignmentAdapter.getSelectors().selectEntities(state),
  );

  public static readonly getSelectedId = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.selectedId,
  );

  //eslint-disable-next-line max-len
  public static readonly getSelected = createSelector(
    this.getAssignmentEntities,
    this.getSelectedId,
    (entities, selectedId) => {
      return (selectedId ? entities[selectedId] as AssignmentDetail : undefined);
    },
  );

  //eslint-disable-next-line max-len
  public static readonly selectHasChunksLoad = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.selfAssignment.loadedChunks?.length > 0,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLastChunkId = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.selfAssignment.lastChunk,
  );

  //eslint-disable-next-line max-len
  public static readonly selectSelfAssignment = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => assignmentAdapter.getSelectors().selectAll(state.selfAssignment),
  );

  //eslint-disable-next-line max-len
  public static readonly selectSelfAssignmentLoadingChunk = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.selfAssignment.loadingChunk,
  );

  //eslint-disable-next-line max-len
  public static readonly selectSelfAssignmentLoaded = createSelector(
    AssignmentSelectors.getAssignmentState,
    (state) => state.selfAssignment.loaded,
  );
}
