import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Iban validator tnx to this stackoverflow
 * @see https://stackoverflow.com/questions/44656264/iban-regex-design
 */
export const ibanValidator: ValidatorFn = (control: AbstractControl) => {
  if (!control.value) {
    return { invalidIban: { value: control.value } };
  }

  const ibanStripped: string = control.value.replace(/[^A-Z0-9]+/gi,'') //keep numbers and letters only
    .toUpperCase(); //calculation expects upper-case
  const match: RegExpMatchArray | null = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
  if (!match) {
    return { invalidIban: { value: control.value } };
  }

  const numbericed: string = (match[3] + match[1] + match[2]).replace(/[A-Z]/g, function(ch: string){
    //replace upper-case characters by numbers 10 to 35
    return (ch.charCodeAt(0)-55).toString(10);
  });


  //The resulting number would be to long for javascript to handle without loosing precision.
  //So the trick is to chop the string up in smaller parts.
  const mod97: number | undefined = numbericed.match(/\d{1,7}/g)?.reduce<number>((total: number, curr: string) => {
    return Number(total + curr) % 97;
  }, 0);

  const isValidIban: boolean = (mod97 === 1);

  if (isValidIban) {
    return null;
  }

  return { invalidIban: { value: control.value } };
};
