import { JsonLd } from '@techniek-team/class-transformer';
import { AssignmentStateEnum } from '@tutor-app/enums';
import { Exclude, Expose } from 'class-transformer';

/**
 * The bare minimal version of an Assignment Resource.
 */
export class AssignmentMinimal extends JsonLd {
  /**
   * private storage for the id property
   */
  private internalId!: string;

  /**
   * Setter for The unique Identifier of the assignment.
   */
  @Expose() public set id(id: string) {
    this.internalId = id;
  }

  /**
   * Getter for The unique Identifier of the assignment.
   * @deprecated use {@see getId}
   */
  public get id(): string {
    return this.internalId;
  }

  public override getId(): string {
    return this.internalId;
  }

  /**
   * Getter for the jsonLd iri which uses the internalId as fallback since the
   * Scheduler Api doesn't return actual JsonLD yet.
   */
  public override getIri(): string | undefined {
    if (super.getIri()) {
      return super.getIri();
    }
    return this.internalId;
  }

  /**
   * The state of the assignment.
   * - UNASSIGNED There is no candidate currecntly assigned to this Assignment.
   * - DRAFT There is a candidate assigned to the Assignment but the request for
   *   him/her to do it isn't send yet.
   * - WAITING_FOR_CONFIRMATION A candidate is assigned to the Assignment but
   *   he/she didn't response to it yet. Waiting for either a aproval or decline
   * - CONFIRMED The assigned candidate confirmed that he is going to do the
   *   assignment
   * - FINAL The assignment is finished.
   */
  @Expose() public state!: AssignmentStateEnum;

  /**
   * If true the booking period of this assignment has been closed.
   *
   * When it does most of the manipulation of the Resource can only be executed
   * by someone with admin rights.
   *
   * This property is mainly used in the Scheduler and not in the tutor app.
   */
  @Expose() public bookingPeriodClosed!: boolean;

  /**
   * Synoniem for {@see bookingPeriodClosed}
   */
  @Exclude() public get isBookingPeriodClosed(): boolean {
    return this.bookingPeriodClosed;
  }

  /**
   * Return true if a Candidate is assigned to this assignment.
   */
  @Exclude() public get isAssigned(): boolean {
    return (this.state && this.state !== AssignmentStateEnum.UNASSIGNED);
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isUnassigned(): boolean {
    return this.state === AssignmentStateEnum.UNASSIGNED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isDraft(): boolean {
    return this.state === AssignmentStateEnum.DRAFT;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isWaitingForConfirmation(): boolean {
    return this.state === AssignmentStateEnum.WAITING_FOR_CONFIRMATION;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isProvisionallyConfirmed(): boolean {
    return this.state === AssignmentStateEnum.PROVISIONALLY_CONFIRMED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isConfirmed(): boolean {
    return this.state === AssignmentStateEnum.CONFIRMED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isCompleted(): boolean {
    return this.state === AssignmentStateEnum.COMPLETED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isApproved(): boolean {
    return this.state === AssignmentStateEnum.APPROVED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isFinal(): boolean {
    return this.state === AssignmentStateEnum.FINAL;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public get stateKebabCase(): string {
    return this.state.replace(/_/g, '-').toLowerCase();
  }
}
