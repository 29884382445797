import { Expose, Type } from 'class-transformer';
import { LocationModelOld } from './locationModelOld';
import { Region } from './region.model';

/**
 * Detailed version of the Location resource from Scheduler-api or Lyceo-HRM.
 */
export class LocationDetailed extends LocationModelOld {

  /**
   * City where the location resides.
   */
  @Expose() public override city!: string;

  /**
   * Hrm returns locations where the city name is stored in the property town where the scheduler calls this property
   * city. So we only allow to set it here to make location consistent within the application
   */
  @Expose() public set town(city: string) {
    if (!city) {
      return;
    }
    this.city = city;
  }

  /**
   * Setter for street number suffix
   *
   * Hrm returns location with a separate streetNumberSuffix where scheduler returns
   * Locations only with a streetNumber which already includes the suffix. (it
   * seperated in the database but it's merge for us, sigh)
   * So we only allow to set it here to make location consistent within the application
   *
   * Warning: this property is only set if the location comes from Lyceo Hrm
   */
  @Expose() public streetNumberSuffix?: string;

  /**
   * Internal storage for {@see streetNumber}
   */
  //eslint-disable-next-line @typescript-eslint/naming-convention
  private _streetNumber!: string;

  /**
   * Setter for street number without suffix.
   *
   * Hrm returns location with a separate streetNumberSuffix where scheduler returns
   * Locations only with a streetNumber which already includes the suffix. (it
   * seperated in the database but it's merge for us, sigh)
   * So we only allow to set it here to make location consistent within the application
   *
   * Warning: this property is only set if the location comes from Lyceo Hrm
   */
  @Expose() public set streetNumber(streetNumber: string) {
    this._streetNumber = streetNumber;
  }

  /**
   * Street number of the location.
   */
  public get streetNumber(): string {
    if (this.streetNumberSuffix) {
      return `${this._streetNumber} ${this.streetNumberSuffix}`;
    }
    return this._streetNumber;
  }

  /**
   * Street number of the location, without any suffix.
   */
  public get streetNumberOnly(): string {
    return this._streetNumber;
  }

  /**
   * PostalCode of the location.
   */
  @Expose() public postalCode!: string;

  /**
   * Street name of the location.
   */
  @Expose() public street!: string;

  /**
   * The region or province where this location resides.
   *
   * The property is nullable since Lyceo-hrm doesn't expose a region property
   */
  @Type(() => Region)
  @Expose() public region?: Region;

  /**
   * Returns the location name if it exists. Otherwise, it returns the address.
   */
  public override get getName(): string {
    return this?.name ?? `${this.street} ${this.streetNumber}, ${this.city}`;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.getName;
  }
}
