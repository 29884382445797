import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-menu-layout',
  templateUrl: 'menu-layout.page.html',
  styleUrls: ['menu-layout.page.scss'],
})
export class MenuLayoutPage {

  constructor(
    protected popoverController: PopoverController,
    protected modalController: ModalController,
  ) {
  }

  public closeAllOverlays(): Promise<void> {
    return Promise.all([
      this.popoverController.getTop().then(overlay => {
        if (overlay) {
          return this.popoverController.dismiss();
        }
      }),
      this.modalController.getTop().then(overlay => {
        if (overlay) {
          return this.modalController.dismiss();
        }
      }),
    ]).then();
  }
}
