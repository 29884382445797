import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Assignment, AssignmentDetail, CandidateRejection } from '@tutor-app/models';

// Compiler will infer the type
//eslint-disable-next-line @typescript-eslint/typedef
export const assignmentsActions = createActionGroup({
  source: 'Assignment/API',
  events: {
    'Init Assignments': emptyProps(),
    'Load Assignments': emptyProps(),
    'Reload Assignments': emptyProps(),
    'Load Assignments Success': props<{ assignments: Assignment[] }>(),
    'Load Assignments Failure': props<{ error: Error }>(),
    'Load Selected Assignment': props<{ assignment: string }>(),
    'Set Selected Assignment': props<{ selectedId: string | undefined }>(),
    'Load Selected Assignment Success': props<{ assignment: AssignmentDetail }>(),
    'Load Selected Assignment Failure': props<{ error: Error }>(),
    'Init Self Assignments': emptyProps(),
    'Next Self Assignments Chunk': emptyProps(),
    'Next Self Assignments Chunk Success': props<{ assignments: Assignment[]; total?: number; chunkId: number }>(),
    'Next Self Assignments Chunk Failure': props<{ error: Error }>(),
    'Clear Self Assignments': emptyProps(),
    'Reload Self Assignments': emptyProps(),
    'Reject Self Assignment': props<{ assignment: Assignment }>(),
    'Reject Self Assignment Success': props<{ assignment: Assignment; candidateRejections: CandidateRejection[] }>(),
    'Reject Self Assignment Failure': props<{ error: Error }>(),
    'Undo last Self Assignment Rejection': emptyProps(),
    'Undo last Self Assignment Rejection Success': emptyProps(),
    'Undo last Self Assignment Rejection Failure': props<{ error: Error }>(),
  },
});
