import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { addDays, isBefore, isDate, parseISO } from 'date-fns';
import { of, tap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PersonApi } from '../../../api/hrm/person/person.api';
import { usersActions } from './user.actions';
import { UserSelectors } from './user.selectors';

@Injectable()
export class UserEffects {
  private loadUser$;

  private loadUserFailure$;

  private reloadUser$;

  private reloadUserSuccess$;

  private reloadUserFailure$;

  private updateUser$;

  private updateUserSuccess$;

  private updateUserFailure$;

  private postAvatar$;

  private postAvatarSuccess$;

  private postAvatarFailure$;

  private deleteAvatar$;

  private deleteAvatarSuccess$;

  private deleteAvatarFailure$;

  private initOnBoarding$;

  private setOnBoarding$;

  private initShowHomeAvailabilityTip$;

  private setShowHomeAvailabilityTip$;

  constructor(
    private personApi: PersonApi,
    private actions$: Actions,
    private toastService: ToastService,
    private sentryErrorHandler: SentryErrorHandler,
    private readonly store: Store,
    private storage: Storage,
    private router: Router,
  ) {
    this.loadUser$ = this.loadUserEffect();
    this.loadUserFailure$ = this.loadUserFailureEffect();
    this.reloadUser$ = this.reloadUserEffect();
    this.reloadUserSuccess$ = this.reloadUserSuccessEffect();
    this.reloadUserFailure$ = this.reloadUserFailureEffect();
    this.updateUser$ = this.updateUserEffect();
    this.updateUserSuccess$ = this.updateUserSuccessEffect();
    this.updateUserFailure$ = this.updateUserFailureEffect();
    this.postAvatar$ = this.postAvatarEffect();
    this.postAvatarSuccess$ = this.postAvatarSuccessEffect();
    this.postAvatarFailure$ = this.postAvatarFailureEffect();
    this.deleteAvatar$ = this.deleteAvatarEffect();
    this.deleteAvatarSuccess$ = this.deleteAvatarSuccessEffect();
    this.deleteAvatarFailure$ = this.deleteAvatarFailureEffect();
    this.initOnBoarding$ = this.createInitOnBoardingEffect();
    this.setOnBoarding$ = this.createSetOnBoardingEffect();
    this.initShowHomeAvailabilityTip$ = this.createInitShowHomeAvailabilityTipEffect();
    this.setShowHomeAvailabilityTip$ = this.createSetShowHomeAvailabilityTipEffect();
  }

  public createInitOnBoardingEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.initUserOnBoarding),
        mergeMap(() => fromPromise(this.storage.create())),
        mergeMap(() => {
          return fromPromise(this.storage.get('onBoarding')).pipe(
            map(response => {
              let date: Date = parseISO(response);
              if (!isDate(date)) {
                return usersActions.setUserOnBoarding({
                  value: new Date(),
                });
              }

              return usersActions.setUserOnBoardingSuccess({
                value: isBefore(new Date(), addDays(date, 7)),
              });
            }),
            catchError(error => of(usersActions.setUserOnBoardingFailure({
              error: error,
            }))),
          );
        }),
      ));
  }

  public createSetOnBoardingEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.setUserOnBoarding),
        mergeMap((action) => {
          return fromPromise(
            this.storage.set('onBoarding', action.value),
          ).pipe(
            map(() => {
              return usersActions.setUserOnBoardingSuccess({
                value: true,
              });
            }),
            catchError(error => of(usersActions.setUserOnBoardingFailure({
              error: error,
            }))),
          );
        }),
      ));
  }

  public createInitShowHomeAvailabilityTipEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.initUserHomeAvailabilityTip),
        mergeMap(() => fromPromise(this.storage.create())),
        mergeMap(() => {
          return fromPromise(this.storage.get('showHomeAvailabilityTip')).pipe(
            map(response => {
              if (typeof response !== 'boolean') {
                response = true;
              }
              return usersActions.setUserHomeAvailabilityTipSuccess({
                value: response,
              });
            }),
            catchError(error => of(usersActions.setUserHomeAvailabilityTipFailure({
              error: error,
            }))),
          );
        }),
      ));
  }

  public createSetShowHomeAvailabilityTipEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.setUserHomeAvailabilityTip),
        mergeMap((action) => {
          return fromPromise(
            this.storage.set('showHomeAvailabilityTip', action.value),
          ).pipe(
            map(() => {
              return usersActions.setUserHomeAvailabilityTipSuccess({
                value: action.value,
              });
            }),
            catchError(error => of(usersActions.setUserHomeAvailabilityTipFailure({
              error: error,
            }))),
          );
        }),
      ));
  }

  private loadUserEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(
        usersActions.initUser,
        usersActions.postAvatarSuccess,
        usersActions.deleteAvatarSuccess,
      ),
      mergeMap(() => {
        return this.personApi.getPerson().pipe(
          map(response => usersActions.loadUserSuccess({ user: response })),
          catchError(error => of(usersActions.loadUserFailure({ error: error }))),
        );
      }),
    ));
  }

  private loadUserFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(
          usersActions.loadUserFailure,
          usersActions.reloadUserFailure,
        ),
        tap((action) => {
          return this.sentryErrorHandler.captureError(action.error)
            .then(() => this.toastService.error({
              message: 'Er is iets mis gegaan bij het ophalen van je gegevens',
              duration: 3000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            }));
        }),
      ), { dispatch: false });
  }

  private reloadUserEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(
          usersActions.reloadUser,
        ),
        mergeMap((action) => {
          return this.personApi.getPerson().pipe(
            map(response => usersActions.reloadUserSuccess({ user: response, refreshEvent: action.refreshEvent })),
            //eslint-disable-next-line max-len
            catchError(error => of(usersActions.reloadUserFailure({
              error: error,
              refreshEvent: action.refreshEvent,
            }))),
          );
        }),
      ),
    );
  }

  private reloadUserSuccessEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.reloadUserSuccess),
        tap((action) => {
          action.refreshEvent.target.complete();
        }),
      ), { dispatch: false });
  }

  private reloadUserFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.reloadUserFailure),
        tap((action) => {
          action.refreshEvent.target.cancel();
        }),
      ), { dispatch: false });
  }

  private updateUserEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.updateUser),
        mergeMap((action) => {
          return this.personApi.updatePerson(action.request).pipe(
            map(response => usersActions.updateUserSuccess({ user: response })),
            catchError(error => of(usersActions.updateUserFailure({ error: error }))),
          );
        }),
      ),
    );
  }

  private updateUserSuccessEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.updateUserSuccess),
        tap(() => {
          return this.toastService.create({
            message: 'Je gegevens zijn bijgewerkt!',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          }).then(() => {
            return this.router.navigate(['mijn-gegevens']);
          });
        }),
      ), { dispatch: false });
  }

  private updateUserFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.updateUserFailure),
        tap(() => {
          return this.toastService.error({
            message: 'Het bijwerken van je gegevens is mislukt.',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ), { dispatch: false });
  }

  private postAvatarEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.postAvatar),
        concatLatestFrom(() => this.store.select(UserSelectors.selectUser)),
        mergeMap(([action, user]) => {
          return this.personApi.postAvatar(user?.getId() as string, action.file).pipe(
            map(() => usersActions.postAvatarSuccess()),
            catchError(error => of(usersActions.postAvatarFailure({ error: error }))),
          );
        }),
      ),
    );
  }

  private postAvatarSuccessEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.postAvatarSuccess),
        tap(() => {
          return this.toastService.create({
            message: 'Je profielfoto is succesvol opgeslagen!',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ), { dispatch: false });
  }

  private postAvatarFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.postAvatarFailure),
        tap(() => {
          return this.toastService.error({
            message: 'Oeps! Er is iets misgegaan bij het opslaan van je profielfoto.',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ), { dispatch: false });
  }

  private deleteAvatarEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.deleteAvatar),
        concatLatestFrom(() => this.store.select(UserSelectors.selectUser)),
        mergeMap(([_, user]) => {
          return this.personApi.deleteAvatar(user?.getId() as string).pipe(
            map(() => usersActions.deleteAvatarSuccess()),
            catchError(error => of(usersActions.deleteAvatarFailure({ error: error }))),
          );
        }),
      ),
    );
  }

  private deleteAvatarSuccessEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.deleteAvatarSuccess),
        tap(() => {
          return this.toastService.create({
            message: 'Profielfoto verwijderd!',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ), { dispatch: false });
  }

  private deleteAvatarFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(usersActions.deleteAvatarFailure),
        tap((action) => {
          return this.sentryErrorHandler.captureError(action.error)
            .then(() => this.toastService.error({
              message: 'Oeps! Er is iets misgegaan bij het verwijderen.',
              duration: 3000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            }));
        }),
      ), { dispatch: false });
  }

}
