import { JsonLd } from '@techniek-team/class-transformer';
import { Expose } from 'class-transformer';

export class EducationProgram extends JsonLd {
  /**
   * returns true if this is the main educational program a person is following
   */
  @Expose() public isMain!: boolean;

  /**
   * The year a the person started with this program.
   */
  @Expose() public yearStart?: number;

  /**
   * The year that the person expects to complete the program.
   */
  @Expose() public yearCompleted?: number;

  /**
   * The name of the institute where the program is given.
   */
  @Expose() public instituteName?: string;

  /**
   * The name of the programme.
   */
  @Expose() public programmeName?: string;
}
