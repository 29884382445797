import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValidBsn } from '../../functions/is-valid-bsn/is-valid-bsn.function';

export const bsnValidator: ValidatorFn = (control: AbstractControl) => {
  const isValid: boolean = isValidBsn(control.value);

  if (isValid) {
    return null;
  }

  return { invalidBsn: { value: control.value } };
};

