import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DataAccessAssignmentsModule } from '@tutor-app/data-access-assignments';
import { HeaderComponent } from '../shared/header/header.component';
import { UserDataAccessModule } from '../store/user/user-data-access.module';
import { IconsModule } from './icons.module';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UserDataAccessModule,
    DataAccessAssignmentsModule,
  ],
  exports: [
    CommonModule,
    IonicModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HeaderComponent,
    UserDataAccessModule,
    DataAccessAssignmentsModule,
  ],
})
export class CoreModule {
}
