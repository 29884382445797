import { DocumentTypeEnum } from '@tutor-app/enums';
import { Exclude, Expose, Type } from 'class-transformer';

/**
 * The standard version of the AssignmentDocument resource from Scheduler-api
 */
export class AssignmentDocument {

  /**
   * The unique Identifier of the Document.
   */
  @Expose() public id!: string;

  /**
   * The date when the document can be made available to the candidate.
   */
  @Type(() => Date)
  @Expose() public publicationStart!: Date;

  /**
   * Name of the document
   */
  @Expose() public title!: string;

  /**
   * The type of document
   */
  @Expose() public type!: DocumentTypeEnum;

  /**
   * The url to the publicly available document.
   */
  @Exclude() private publicUrl!: string;

  /**
   * The url to the aws bucket where private document are stored.
   */
  @Expose() public signedUrl?: string;

  /**
   * The unique identifier of the AssignmentHasDocument Record.
   */
  @Expose() public assignmentHasDocumentId!: string;

  /**
   * Setter to set a public url
   */
  @Expose()
  public set url(url: string) {
    this.publicUrl = url;
  }

  /**
   * Returns the url of the document, which can either be a {@see self.signedUrl}
   * or {@see self.publicUrl}
   */
  public get url(): string {
    return (this.signedUrl) ? this.signedUrl : this.publicUrl;
  }
}
