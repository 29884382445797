import { Expose } from 'class-transformer';

export class EventGroup {
  @Expose() public name!: string;


  public toString(): string {
    return this.name;
  }
}
