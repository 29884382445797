import { NgModule, Type } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '@tutor-app/environments';
import { OAuthGuard } from './core/oauth.guard';
import { MenuLayoutPage } from './menu-layout/menu-layout.page';

const routes: Routes = [
  {
    path: '',
    canActivate: [OAuthGuard],
    component: MenuLayoutPage,
    data: { grants: ['ROLE_SKOLEO_TT_PLANNER_TUTOR'] },
    children: [
      {
        path: 'home',
        pathMatch: 'full',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./home/home.module')
          .then(m => m.HomePageModule),
      },
      {
        path: 'agenda',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./calendar/calendar.module')
          .then( m => m.CalendarPageModule),
      },
      {
        /** @deprecated 2023-03-14 kept redirect to smooth the transitation. Can be removed with next major update */
        path: 'beschikbaarheid',
        redirectTo: 'agenda',
      },
      {
        /** @deprecated 2023-03-14 kept redirect to smooth the transitation. Can be removed with next major update */
        path: 'beschikbaarheid/lange-termijn',
        redirectTo: 'agenda',
      },
      {
        /** @deprecated 2023-03-14 kept redirect to smooth the transitation. Can be removed with next major update */
        path: 'beschikbaarheid/piek',
        redirectTo: 'agenda',
      },
      {
        path: 'opdrachten',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./assignments/assignments.module')
          .then(m => m.AssignmentsModule),
      },
      {
        path: 'uitbetalingen',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./payout/payout/payout.module')
          .then(m => m.PayoutModule),
      },
      {
        path: 'uitbetalingen/:id',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./payout/payout-detail/payout-detail.module')
          .then(m => m.PayoutDetailModule),
      },
      {
        path: 'opdrachten/toevoegen',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import(
          './assignments/assignment-coaching-create/assignment-coaching-create.module'
        ).then(m => m.AssignmentCoachingCreatePageModule),
      },
      {
        path: 'opdrachten/:id',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./assignments/assignment-detail/assignment-detail.module')
          .then(m => m.AssignmentDetailModule),
      },
      {
        path: 'mijn-gegevens',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./profile/profile.module')
          .then(m => m.ProfileModule),
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'help',
    loadChildren: (): Promise<Type<unknown>> => import('./profile/help/help.module')
      .then(m => m.HelpPageModule),
    canActivate: [OAuthGuard],
    data: { grants: ['ROLE_SKOLEO_TT_PLANNER_TUTOR'] },
  },
  {
    /**
     * @see EntryPage
     */
    path: 'login',
    loadChildren: (): Promise<Type<unknown>> => import('./entry/entry.module')
      .then(m => m.EntryModule),
  },
  {
    /**
     * @see AccessDeniedPage
     */
    path: 'geen-toegang',
    loadChildren: (): Promise<Type<unknown>> => import('./access-denied/access-denied.module')
      .then(m => m.AccessDeniedModule),
  },
  {
    /**
     * @see JobApplicationPage
     */
    path: 'sollicitatie',
    loadChildren: (): Promise<Type<unknown>> => import('./job-application/job-application.module')
      .then(m => m.JobApplicationModule),
    canActivate: [OAuthGuard],
    data: { grants: (environment.environment === 'local') ? [] : ['GRANT_APPLICANT'] },
  },
  {
    path: 'finish-login',
    redirectTo: 'authenticate',
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })], exports: [RouterModule],
})
export class AppRoutingModule {
}
