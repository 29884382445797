import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapConfig } from './google-api-loader';
import { TtMapHtmlMarkerComponent } from './map-html-marker/tt-map-html-marker.component';

@NgModule({
  declarations: [
    TtMapHtmlMarkerComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
  ],
  exports: [
    TtMapHtmlMarkerComponent,
    GoogleMapsModule,
  ],
})
export class TTGoogleMapsModule {
  public static forRoot(config: GoogleMapConfig): ModuleWithProviders<TTGoogleMapsModule> {
    return {
      ngModule: TTGoogleMapsModule,
      providers: [
        {
          provide: GoogleMapConfig, useValue: config,
        },
      ],
    };
  }
}
