import { GenderEnum } from '@tutor-app/enums';
import { Expose, Type } from 'class-transformer';
import { EducationProgram } from '../education-program.model';
import { LocationDetailed } from '../location/location-detailed.model';
import { Person } from './person.model';

export class PersonDetailed extends Person {
  @Expose({ name: 'id' }) public id!: string;

  public override getId(): string {
    return this.id;
  }

  /**
   * @deprecated use the uuid  identifier {@see JsonLd.getId} or
   * {@see JsonLd.getIri} instead.
   */
  @Expose() public skoleoId!: number;

  /**
   * This initials of the person.
   */
  @Expose() public initials?: string;

  /**
   * First name of the person.
   */
  @Expose() public firstName!: string;

  /**
   * Middle name of this person, if any. for example "van der"
   */
  @Expose() public middleName?: string;

  /**
   * Surname of this person
   */
  @Expose() public lastName!: string;

  /**
   * specifies if the person is male, female or other
   *
   * If undefined we don't know this, for example because the person didn't want
   * to share this information.
   */
  @Expose() public gender?: GenderEnum;

  /**
   * is true if we know that this person has an active driving license.
   * If undefined we don't know this.
   */
  @Expose() public hasDriversLicense?: boolean;

  /**
   * is true if we know that this person has a driveable car.
   * If undefined we don't know this.
   */
  @Expose() public hasCar?: boolean;

  /**
   * The birthdate of the person.
   */
  @Type(() => Date)
  @Expose() public dateOfBirth!: Date;

  /**
   * The Iban of the persons bankaccount.
   */
  @Expose() public iban?: string;

  /**
   * The phone number of the person.
   */
  @Expose() public phoneNumber?: string;

  /**
   * The alternative phone number of the person in case he has given any.
   */
  @Expose() public phoneNumberAlternative?: string;

  /**
   * The emailAddress of the person.
   */
  @Expose() public emailAddress!: string;

  @Type(() => LocationDetailed)
  @Expose() public mainAddress!: LocationDetailed;

  /**
   * Url to the profile photo's of the person.
   */
  @Expose() public profilePhotoUrl?: string;

  @Type(() => EducationProgram)
  @Expose() public attendsEducationPrograms: EducationProgram[] = [];


  /**
   * If the user has set an avatar use this one, otherwise
   * fallback to the brand logo
   */
  public getProfileUrl(): string {
    const defaultImage: string = 'assets/imgs/profile.svg';

    const url: string = this.profilePhotoUrl || defaultImage;

    return `url(${url})`;
  }

}
