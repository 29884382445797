export class InvalidGrantError extends Error {
  public roles: string[] | undefined;

  constructor(msg: string = 'InvalidGrantError!', roles?: string[]) {
    super(msg);
    // see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = InvalidGrantError.name; // stack traces display correctly now
    this.roles = roles;
  }
}
