import { TsRange } from '@techniek-team/class-transformer';
import { AssignmentHasSlotPurposeEnum, LevelEnum } from '@tutor-app/enums';
import { Expose, Type } from 'class-transformer';
import { Role } from '../../role.model';
import { Slot } from '../../slot/slot.model';
import { SubjectModel } from '../../subject.model';
import { AssignmentHasSlotMinimal } from './assignment-has-slot-minimal.model';

/**
 * The Detailed version of the AssignmentHasSlot resource from Scheduler-api
 */
export class AssignmentHasSlotDetailed extends AssignmentHasSlotMinimal {

  /**
   * Time in minutes of how long the break was
   */
  @Expose() public breakTime?: number;

  /**
   * The actual times that the candidate has worked on the slots, which
   * can be different from the actually planned hours.
   */
  @Type(() => TsRange)
  @Expose() public actualTimePeriod?: TsRange;

  @Type(() => Date)
  @Expose() public actualTimePeriodUpdatedAt?: Date;

  /**
   * The slot or shift a candidate needs to preform.
   */
  @Type(() => Slot)
  @Expose() public slot!: Slot;

  @Expose() public purpose!: AssignmentHasSlotPurposeEnum;

  /**
   * Returns either the actualTimePeriod or the slot timePeriod when the actual
   * isn't available.
   */
  public get realTimePeriod(): TsRange {
    if (this.actualTimePeriod) {
      return this.actualTimePeriod;
    }
    return this.slot.timePeriod;
  }

  /**
   * Returns the start date of this assignment.
   */
  public get startDate(): Date {
    return this.slot.timePeriod.start;
  }

  /**
   * Returns the end date of this assignment.
   */
  public get endDate(): Date {
    return this.slot.timePeriod.end;
  }

  /**
   * Returns the subject of this assignment.
   */
  public get subject(): SubjectModel | undefined {
    return this.slot.lesson.subject;
  }

  /**
   * Returns the level of this assignment.
   */
  public get level(): LevelEnum | undefined {
    return this.slot.lesson.level;
  }

  /**
   * Returns the level of this assignment.
   */
  public get role(): Role {
    return this.slot.role;
  }
}
