import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
//import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { OAuthService } from '@techniek-team/oauth';
import { setDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale';
import { PushNotificationService } from './core/push-notification/push-notification.service';
import { UserStoreService } from './store/user/+state/user-store.service';

setDefaultOptions({
  locale: nl,
  weekStartsOn: 1,
});

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private platform: Platform,
    private oauthService: OAuthService,
    private router: Router,
    private pushService: PushNotificationService,
    private storage: Storage,
    private zone: NgZone,
    private userStoreService: UserStoreService,
  ) {
  }

  public ngOnInit(): void {
    //noinspection JSIgnoredPromiseFromCall
    this.checkLocalStorage();
    this.initializeApp();
    this.userStoreService.init();
  }

  /**
   * @private
   */
  private initializeApp(): void {
    this.platform.ready().then(() => {
      this.storage.get('show_help').then(showHelp => {
        if (showHelp === false) {
          return;
        }
        return this.router.navigate(['/help']);
      });

      if (this.platform.is('capacitor')) {
        //noinspection JSIgnoredPromiseFromCall
        // todo this package is not maintained anymore
        //NavigationBar.setColor({ color: '#1F2A44', darkButtons: false });
        SplashScreen.hide();
        this.initializeDeepLinks();
        this.pushService.redirectOnPushNotification();
      }

      document.getElementById('init-app-loading')?.remove();
    });
  }

  /**
   * Match the path when we redirect the user from an * external url from the inAppBrowser or the mobile browser
   * @private
   */
  private initializeDeepLinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {

        if (event.url.match(/.*\/ios-login\.html/) || event.url.match(/werkenbijlyceo:\/\//)) {
          const url: URL = new URL(event.url);
          const queryParams: Record<string, unknown> = {};
          for (let [key, value] of url.searchParams.entries()) {
            queryParams[key] = value;
          }

          Browser.close().catch(() => { /* an error is thrown when nothing can be closed, this is fine */ });

          return this.router.navigate(['/', 'authenticate'], { queryParams: queryParams });
        }

        // Example url: https://werkenbijapp.lyceo.nl/tab2
        // slug = /tab2
        const parts: string[] = event.url.split('.nl');
        if (parts.length > 1) {
          return this.router.navigateByUrl(parts.pop() as string);
        }
        // If no match, do nothing - let regular routing
        // logic take over
        return Promise.resolve(false);
      });
    });
  }

  /**
   * Checks if device is able to use Storage and initializes it.
   */
  private async checkLocalStorage(): Promise<void> {
    try {
      await this.storage.create();
    } catch (e) {
      alert('Er kunnen geen gegevens worden opgeslagen omdat je een'
        + ' verouderde browser of privé- of incognitomodus gebruikt. De app'
        + ' zal helaas niet werken.');
    }
  }

}
