import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PluginListenerHandle } from '@capacitor/core';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { ActionPerformed, PushNotifications, RegistrationError, Token } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UserService } from '@techniek-team/oauth';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { CandidateApi } from '../../api/candidate/candidate.api';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  constructor(
    private authService: UserService,
    private platform: Platform,
    private storage: Storage,
    private candidateApi: CandidateApi,
    private router: Router,
    private sentryErrorHandler: SentryErrorHandler,
  ) {}

  /**
   * Check if there is a token in the localStorage, if not
   * request permission for push notification
   */
  public async requestToken(): Promise<void> {
    const hasToken: string = await this.storage.get('fcm_registration_token');

    if (!this.platform.is('capacitor') || hasToken) {
      return;
    }

    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }
    });

    this.createRegistrationListener();

    PushNotifications.addListener('registrationError',
      (error: RegistrationError) => {
        return this.sentryErrorHandler.captureError(new Error(error.error));
      },
    );
  }

  private createRegistrationListener(): void {
    const listener: PluginListenerHandle = PushNotifications.addListener(
      'registration',
      async (token: Token) => {
        try {
          const deviceInfo: DeviceInfo = await Device.getInfo();
          const deviceId: DeviceId = await Device.getId();
          // send the token and user data to the backend
          await firstEmitFrom(this.candidateApi.postToken({
            client: 'TUTOR_APP_LYCEO',
            registerToken: token.value,
            device: deviceInfo.model,
            deviceId: deviceId.identifier,
          }));

          await listener.remove();
          return this.storage.set('fcm_registration_token', token.value);
        } catch (error) {
          return this.sentryErrorHandler.captureError(error);
        }
      },
    );
  }

  /**
   * Apparently the deeplinks plugin doesn't recognize the url
   * provided in the click_action, so we have to redirect the
   * user manually
   *
   * Note: when the user clicks the push notification 'tap' property
   * in the response is set to true
   */

  public redirectOnPushNotification(): void {
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      if (notification.notification.data['deeplink']) {
        return this.router.navigateByUrl(notification.notification.data['deeplink']);
      }
    });
  }
}
