import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { Assignment } from '@tutor-app/models';
import { assignmentsActions } from './assignment.actions';

export const assignmentFeatureKey: string = 'assignment';

export interface SelfAssignmentState extends EntityState<Assignment> {
  loaded: boolean;
  loadingChunk: boolean;
  loadedChunks: number[];
  lastChunk: number | null;
  totalItems: number | null;
  lastRejectedAssignments: Assignment | null;
  lastRejectedAssignmentIndexes: number | null;
}

export interface AssignmentState extends EntityState<Assignment> {
  loaded: boolean;

  loading: boolean;

  loadedSelectedAssignment: boolean;

  loadingSelectedAssignment: boolean;

  error?: Error | null;

  selectedId: string | null;

  selfAssignment: SelfAssignmentState;
}

export function selectAssignment(item: Assignment): string {
  return item.getIri() as string;
}

export const assignmentAdapter: EntityAdapter<Assignment> = createEntityAdapter<Assignment>({
  selectId: selectAssignment,
  // Ngrx will keep the sorting from the backend by setting the explicitly to false.
  sortComparer: false,
});

export const initialState: AssignmentState = assignmentAdapter.getInitialState({
  loaded: false,
  loading: false,
  loadedSelectedAssignment: false,
  loadingSelectedAssignment: false,
  error: null,
  selectedId: null,
  selfAssignment: assignmentAdapter.getInitialState({
    loaded: false,
    loadingChunk: false,
    loadedChunks: [],
    lastChunk: null,
    totalItems: null,
    sortingOrder: [],
    lastRejectedAssignments: null,
    lastRejectedAssignmentIndexes: null,
  }),
});

export const assignmentReducer: ActionReducer<AssignmentState> = createReducer(
  initialState,
  on(assignmentsActions.loadAssignments, state => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(assignmentsActions.loadAssignmentsSuccess, (state, { assignments }) => {
    return assignmentAdapter.setAll(assignments, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(assignmentsActions.loadAssignmentsFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(assignmentsActions.loadSelectedAssignment, state => {
    return {
      ...state,
      loadingSelectedAssignment: true,
      loadedSelectedAssignment: false,
    };
  }),
  on(assignmentsActions.loadSelectedAssignmentSuccess, (state, { assignment }) => {
    return assignmentAdapter.setOne(assignment, {
      ...state,
      selectedId: assignment.getIri() as string,
      loadingSelectedAssignment: false,
      loadedSelectedAssignment: true,
    });
  }),
  on(assignmentsActions.initSelfAssignments, state => {
    return {
      ...state,
      selfAssignment: {
        ...state.selfAssignment,
        loadingChunk: true,
      },
    };
  }),
  on(assignmentsActions.nextSelfAssignmentsChunk, state => {
    return {
      ...state,
      selfAssignment: {
        ...state.selfAssignment,
        loadingChunk: true,
      },
    };
  }),
  on(assignmentsActions.nextSelfAssignmentsChunkSuccess, (state, { chunkId, assignments, total }) => {
    return {
      ...state,
      selfAssignment: assignmentAdapter.setMany(assignments, {
        ...state.selfAssignment,
        loadingChunk: false,
        loaded: true,
        loadedChunks: [...state.selfAssignment.loadedChunks, chunkId],
        lastChunk: chunkId,
        totalItems: total as number,
      }),
    };
  }),
  on(assignmentsActions.reloadSelfAssignments, state => {
    return {
      ...state,
      selfAssignment: {
        entities: {},
        ids: [],
        lastChunk: null,
        loadedChunks: [],
        loaded: false,
        totalItems: null,
        loadingChunk: false,
        lastRejectedAssignments: null,
        lastRejectedAssignmentIndexes: null,
      },
    };
  }),
  on(assignmentsActions.rejectSelfAssignmentSuccess,(state, { assignment }) => {
    const index: number = state.selfAssignment.ids.findIndex(id => id === selectAssignment(assignment));
    return {
      ...state,
      selfAssignment: assignmentAdapter.removeOne(assignment.getIri() as string, {
        ...state.selfAssignment,
        lastRejectedAssignments: state.selfAssignment.entities[selectAssignment(assignment)] ?? null,
        lastRejectedAssignmentIndexes: (index < 0) ? null : index,
      }),
    };
  }),
  on(assignmentsActions.undoLastSelfAssignmentRejectionSuccess, state => {
    const lastReject = state.selfAssignment.lastRejectedAssignments;

    let entities: Dictionary<Assignment> = { ...state.selfAssignment.entities };
    let ids: string[] | number[] = [...state.selfAssignment.ids] as string[] | number[];
    if (lastReject) {
      const lastRejectedAssignmentId: string = selectAssignment(lastReject);
      entities[lastRejectedAssignmentId] = lastReject;

      ids.splice(state.selfAssignment.lastRejectedAssignmentIndexes as number, 0, lastRejectedAssignmentId);
    }

    return {
      ...state,
      selfAssignment: {
        ...state.selfAssignment,
        entities: entities,
        ids: ids,
        lastRejectedAssignments: null,
        lastRejectedAssignmentIndexes: null,
      },
    };
  }),
  on(assignmentsActions.rejectSelfAssignmentFailure, (state, { error }) => ({
    ...state,
    selfAssignment: {
      ...state.selfAssignment,
      error: error,
    },
  })),
  on(assignmentsActions.undoLastSelfAssignmentRejectionFailure, (state, { error }) => ({
    ...state,
    selfAssignment: {
      ...state.selfAssignment,
      error: error,
    },
  })),
);
