import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowUpRightFromSquare, faClipboardMedical } from '@fortawesome/pro-duotone-svg-icons';
import { faBook, faFeatherPointed } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowProgress,
  faArrowRightFromBracket,
  faAtomSimple,
  faBell,
  faBriefcase,
  faCalendarDay,
  faCalendarDays,
  faCloudArrowDown,
  faGear,
  faGraduationCap,
  faHouse,
  faIdCardClip,
  faInfoCircle,
  faLink,
  faLocationDot,
  faMessageExclamation,
  faMoneyBill,
  faPen,
  faPlaneDeparture,
  faPlus,
  faTimes,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class IconsModule {
  //eslint-disable-next-line max-lines-per-function
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faHouse,
      faCalendarDays,
      faCalendarDay,
      faBriefcase,
      faMoneyBill,
      faUser,
      faBook,
      faFeatherPointed,
      faLocationDot,
      faBell,
      faGear,
      faMessageExclamation,
      faInfoCircle,
      faArrowRightFromBracket,
      faPlus,
      faGraduationCap,
      faPlaneDeparture,
      faIdCardClip,
      faAtomSimple,
      faArrowProgress,
      faTimes,
      faClipboardMedical,
      faPen,
      faArrowUpRightFromSquare,
      faCloudArrowDown,
      faLink,
    );
  }
}
