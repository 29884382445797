import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DataAccessDeclineReasonsModule } from '@tutor-app/data-access-decline-reasons';
import { assignmentFeatureKey, assignmentReducer } from './+state/assignment.reducer';
import { AssignmentSelfAssignEffects } from './+state/effects/assignment-self-assign.effects';
import { AssignmentEffects } from './+state/effects/assignment.effects';
import { AssignmentStoreService } from './assignment-store.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DataAccessDeclineReasonsModule,
    StoreModule.forFeature(assignmentFeatureKey, assignmentReducer),
    EffectsModule.forFeature([
      AssignmentEffects,
      AssignmentSelfAssignEffects,
    ]),
  ],
  providers: [
    AssignmentStoreService,
  ],
})
export class DataAccessAssignmentsModule {
}
