import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { DeclineReason } from '@tutor-app/models';
import { Observable, shareReplay } from 'rxjs';
import { declineReasonActions } from './+state/decline-reasons.actions';
import { DeclineReasonsSelectors } from './+state/decline-reasons.selectors';

@Injectable()
export class DeclineReasonsStoreService {
  public loading$: Observable<boolean> = this.store.pipe(select(DeclineReasonsSelectors.selectLoading));

  public loaded$: Observable<boolean> = this.store.pipe(select(DeclineReasonsSelectors.selectLoaded));

  public declineReasons$: Observable<DeclineReason[]> = this.store.pipe(select(DeclineReasonsSelectors.selectAll));

  //eslint-disable-next-line max-len
  public notInterestedReason$: Observable<DeclineReason> = this.store.pipe(
    select(DeclineReasonsSelectors.selectNotInterested),
    isDefined(),
    shareReplay(1),
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {
  }

  public init(): void {
    this.store.dispatch(declineReasonActions.initDeclineReasons());
  }

  public reload(): void {
    this.store.dispatch(declineReasonActions.reloadDeclineReasons());
  }

}
