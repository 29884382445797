import { denormalize, TsRange } from '@techniek-team/class-transformer';
import { Exclude, Expose, Transform } from 'class-transformer';
import {
  addMilliseconds,
  differenceInMinutes,
  format,
  getHours,
  getMilliseconds,
  getMinutes,
  getSeconds,
  set,
  subMilliseconds,
} from 'date-fns';
import { InvalidTimeStringError } from './invalid-time-string.error';

export interface TimeRangeInterface {
  start: string;
  end: string;
  startInclusive: boolean;
  endInclusive: boolean;
}

interface TimeRegexOutput {
  hours: string;
  minutes: string;
  seconds: string;
}

export class TimeRangeModel {

  @Transform(({ value }) => {
    if (value) {
      return TimeRangeModel.transformTimeStringToDate(value);
    }
    return value;
  })
  @Expose() public start: Date;

  @Transform(({ value }) => {
    if (value) {
      return TimeRangeModel.transformTimeStringToDate(value);
    }
    return value;
  })
  @Expose() public end: Date;

  @Expose() public inclusiveStart: boolean = true;

  @Expose() public inclusiveEnd: boolean = false;

  @Exclude()
  public set startInclusive(state: boolean) {
    this.inclusiveStart = state ?? true;
  }

  @Exclude()
  public set endInclusive(state: boolean) {
    this.inclusiveEnd = state ?? true;
  }

  constructor(
    start: Date,
    end: Date,
    inclusiveStart: boolean = true,
    inclusiveEnd: boolean = false,
  ) {
    this.start = set(new Date(), {
      hours: getHours(start),
      minutes: getMinutes(start),
      seconds: getSeconds(start),
      milliseconds: getMilliseconds(start),
    });
    this.end = set(new Date(), {
      hours: getHours(end),
      minutes: getMinutes(end),
      seconds: getSeconds(end),
      milliseconds: getMilliseconds(end),
    });
    this.inclusiveStart = inclusiveStart;
    this.inclusiveEnd = inclusiveEnd;
  }

  /**
   * Either include or exclude the start
   * from the range
   *
   * Warning This is a time range so the day shouldn't be used.
   */
  public min(): Date {
    if (this.inclusiveStart) {
      return this.start;
    }
    return addMilliseconds(this.start, 1);
  }

  /**
   * Either include or exclude the end
   * from the range
   *
   * Warning This is a time range so the day shouldn't be used.
   */
  public max(): Date {
    if (this.inclusiveEnd) {
      return this.end;
    }
    return subMilliseconds(this.end, 1);
  }

  /**
   * Returns the duration in minutes
   */
  public duration(): number {
    return differenceInMinutes(this.min(), this.max());
  }

  /**
   * Returns a human readable string of this time range model
   *
   * Example:
   * 09:30 - 10:22
   */
  public humanReadableString(): string {
    const startTime: string = format(this.start, 'HH:mm');
    const endTime: string = format(this.end, 'HH:mm');
    return startTime + ' - ' + endTime;
  }

  /**
   * Convert this TimeRange into a TsRange where it uses the specified Date as
   * day and this TimeRange as the time on that day.
   */
  public convertToTsRange(day: Date): TsRange {
    return new TsRange(
      set(day, {
        hours: getHours(this.start),
        minutes: getMinutes(this.start),
        seconds: getSeconds(this.start),
        milliseconds: getMilliseconds(this.start),
      }),
      set(day, {
        hours: getHours(this.end),
        minutes: getMinutes(this.end),
        seconds: getSeconds(this.end),
        milliseconds: getMilliseconds(this.end),
      }),
      this.inclusiveStart,
      this.inclusiveEnd,
    );
  }

  /**
   * Instantiate the int-range class using the object
   * returned in the api response
   */
  public static fromObject(data: TimeRangeInterface): TimeRangeModel {
    return denormalize(TimeRangeModel, data);
  }

  /**
   * Helper function which transforms time strings like (example: 09:30:21) to
   * an {@see Date} object we can use.
   */
  public static transformTimeStringToDate(value: string): Date {
    const time: TimeRegexOutput | null = value?.match(
      /(?<hours>\d{1,2}):(?<minutes>\d{1,2})(?::(?<seconds>\d{1,2}))?/,
    )?.groups as unknown as TimeRegexOutput;
    if (time) {
      return set(new Date(), {
        hours: parseInt(time.hours, 10) ?? 0,
        minutes: parseInt(time.minutes, 10) ?? 0,
        seconds: parseInt(time.seconds, 10) ?? 0,
        milliseconds: 0,
      });
    }
    throw new InvalidTimeStringError();
  }

  /**
   * The lambda arrow functions can not be used in static items apparently, so
   * with the fromObject function it died. This fixes that.
   */
  protected static getDate(): typeof Date {
    return Date;
  }

  /**
   * String casting method.
   * @inheritDoc
   */
  public toString(): string {
    return this.humanReadableString();
  }
}
