import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Selector } from '../../selector.type';
import { userFeatureKey, UserState } from './user.reducer';

export class UserSelectors {
  //eslint-disable-next-line max-len
  public static readonly getUserState: MemoizedSelector<object, UserState> = createFeatureSelector<UserState>(
    userFeatureKey,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoading: Selector<boolean> = createSelector(
    UserSelectors.getUserState,
    (state) => state.loading,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoaded: Selector<boolean> = createSelector(
    UserSelectors.getUserState,
    (state) => state.loaded,
  );

  public static readonly selectError = createSelector(
    UserSelectors.getUserState,
    (state) => state.error,
  );

  //eslint-disable-next-line max-len
  public static readonly selectUser: Selector<UserState['user']> = createSelector(
    UserSelectors.getUserState,
    (state) => state.user,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoadingAvatar: Selector<boolean> = createSelector(
    UserSelectors.getUserState,
    (state) => state.loadingAvatar,
  );


  //eslint-disable-next-line max-len
  public static readonly selectShowHomeAvailabilityTip: Selector<boolean> = createSelector(
    UserSelectors.getUserState,
    (state) => state.showHomeAvailabilityTip,
  );

  //eslint-disable-next-line max-len
  public static readonly selectOnBoarding: Selector<boolean> = createSelector(
    UserSelectors.getUserState,
    (state) => state.userOnBoarding,
  );
}
