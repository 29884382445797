import { TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';

/**
 * The bare minimal version of the Lesson Resource.
 */
export class LessonMinimal {
  /**
   * The unique Identifier of the Lesson.
   */
  @Expose() public id!: string;

  /**
   * The Name of the lesson
   */
  @Expose() public name?: string;

  /**
   * The start / end of the lesson.
   */
  @Type(() => TsRange)
  @Expose() public period?: TsRange;

  /**
   * The number of pupils attending the lesson
   */
  @Expose() public numberOfPupils?: number;

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.name ?? `${this.period?.humanReadableString()} - (${this.numberOfPupils} leerlingen)`;
  }
}
