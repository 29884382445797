import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { MenuLayoutPage } from './menu-layout.page';

@NgModule({
  imports: [
    CoreModule,
  ],
  declarations: [
    MenuLayoutPage,
  ],
})
export class MenuLayoutPageModule {
}
