import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resource } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { UserService } from '@techniek-team/oauth';
import { environment } from '@tutor-app/environments';
import { CandidateRejection, DeclineReason } from '@tutor-app/models';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CandidateRejectionRequest } from './candidate-rejection.request';

export interface CandidateRejectionApiInterface {
  postCandidateRejection(request: CandidateRejectionRequest): Observable<CandidateRejection[]>;
}

@Injectable({
  providedIn: 'root',
})
export class CandidateRejectionApi implements CandidateRejectionApiInterface {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
  ) {
  }

  /**
   * Post the slots and rejection reason for the candidate.
   */
  public postCandidateRejection(
    request: CandidateRejectionRequest,
  ): Observable<CandidateRejection[]> {
    return this.userService.currentUser().pipe(
      switchMap(user => {
        //eslint-disable-next-line max-len
        const url: string = `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v1/candidates/${user.id}/rejection`;

        if (request.declineReason instanceof DeclineReason) {
          request.declineReason = request.declineReason.getId();
        }

        return this.httpClient.post<Resource<{}>[]>(url, {
          declineReason: request.declineReason,
          slots: request.slots,
        }).pipe(
          map(response => denormalize(CandidateRejection, response)),
        );
      }),
    );
  }

  public deleteCandidateRejection(id: string): Observable<void> {
    const url: string = `${environment.schedulerApi.url}${environment.schedulerApi.iri}/v3/candidate_rejections/${id}`;
    return this.httpClient.delete<void>(url);
  }
}
