import { Expose, Type } from 'class-transformer';
import { Lesson } from '../lesson/lesson.model';
import { SlotMinimal } from './slot-minimal.model';

/**
 * Standard version of the Slot Resource.
 */
export class Slot extends SlotMinimal {

  /**
   * The lesson where this slot belongs to
   */
  @Type(() => Lesson)
  @Expose() public lesson!: Lesson;

}
