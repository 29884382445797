import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { DeclineReasonsStoreService } from '@tutor-app/data-access-decline-reasons';
import { Assignment, AssignmentDetail } from '@tutor-app/models';
import { firstValueFrom, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { assignmentsActions } from './+state/assignment.actions';
import { AssignmentSelectors } from './+state/assignment.selectors';

@Injectable()
export class AssignmentStoreService {
  public loadingAssignments$ = this.store.pipe(select(AssignmentSelectors.selectLoading));

  public loadedAssignments$: Observable<boolean> = this.store.pipe(select(AssignmentSelectors.selectLoaded));

  //eslint-disable-next-line max-len
  public loadAssignmentsSuccess$: Observable<void> = this.actions$.pipe(ofType(assignmentsActions.loadAssignmentsSuccess));

  public plannedAssignments$: Observable<Assignment[]> = this.store.pipe(select(AssignmentSelectors.selectPlanned));

  public assignmentHistory$: Observable<Assignment[]> = this.store.pipe(select(AssignmentSelectors.selectHistory));

  public selectedAssignment$: Observable<AssignmentDetail> = this.store.pipe(
    select(AssignmentSelectors.getSelected),
    isDefined(),
    filter(selected => (selected instanceof AssignmentDetail)),
  );

  public loadedSelfAssignment$: Observable<boolean> = this.store.pipe(
    select(AssignmentSelectors.selectSelfAssignmentLoaded),
  );

  public loadingSelfAssignmentChunk$ = this.store.pipe(
    select(AssignmentSelectors.selectSelfAssignmentLoaded),
  );

  public selfAssignment$ = this.store.pipe(
    select(AssignmentSelectors.selectSelfAssignment),
    isDefined(),
  );

  public nextChunkSuccess$: Observable<void> = this.actions$.pipe(
    ofType(assignmentsActions.nextSelfAssignmentsChunkSuccess),
  );

  public assignmentRejectionSuccess$: Observable<void> = this.actions$.pipe(
    ofType(assignmentsActions.rejectSelfAssignmentSuccess),
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly declineReasonsStoreService: DeclineReasonsStoreService,
  ) {
    this.declineReasonsStoreService.init();
  }

  public initAssignments(): void {
    this.store.dispatch(assignmentsActions.initAssignments());
  }

  public initAssignmentsIfNeeded(): void {
    firstValueFrom(this.loadedAssignments$).then((loaded) => {
      if (!loaded) {
        this.store.dispatch(assignmentsActions.initAssignments());
      }
    });
  }

  public reloadAssignments(): void {
    this.store.dispatch(assignmentsActions.reloadAssignments());
  }

  public initSelfAssignments(): void {
    this.store.dispatch(assignmentsActions.initSelfAssignments());
  }

  public reloadSelfAssignment(): void {
    this.store.dispatch(assignmentsActions.reloadSelfAssignments());
  }

  public nextSelfAssignmentChunk(): void {
    this.store.dispatch(assignmentsActions.nextSelfAssignmentsChunk());
  }

  public setSelectedAssignment(assignment: string | Assignment): void {
    const id: string = (assignment instanceof Assignment) ? assignment.getIri() as string : assignment;
    this.store.dispatch(assignmentsActions.setSelectedAssignment({ selectedId: id }));
  }

  public clearSelectedAssignment(): void {
    this.store.dispatch(assignmentsActions.setSelectedAssignment({ selectedId: undefined }));
  }

  public rejectSelfAssignment(assignment: Assignment): void {
    this.store.dispatch(assignmentsActions.rejectSelfAssignment({ assignment: assignment }));
  }
}
