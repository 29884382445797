import { Expose, Type } from 'class-transformer';
import { SelectionEvent } from './selection-event.model';

export class SelectionEventParticipation {

  @Expose() public id!: string;

  @Type(() => SelectionEvent)
  @Expose() public event!: SelectionEvent;
}
