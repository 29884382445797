import { instrumentAngularRouting, routingInstrumentation } from '@sentry/angular-ivy';
import { CapacitorOptions } from '@sentry/capacitor';
import { SentryWebConfig } from '@techniek-team/sentry-web';

export enum SentryEnvironment {
  PRODUCTION = 'production',
  LOCAL = 'local',
  TESTING = 'testing',
  ACCEPTANCE = 'acceptance',
}

export class SentryCapacitorConfig extends SentryWebConfig implements CapacitorOptions {

  /**
   * Enables crash reporting for native crashes.
   * Defaults to `true`.
   */
  public enableNative?: boolean;

  /**
   * Enables native crashHandling. This only works if `enableNative` is `true`.
   * Defaults to `true`.
   */
  public enableNativeCrashHandling?: boolean;

  /**
   * Should the native nagger alert be shown or not.
   */
  public enableNativeNagger?: boolean;

  /**
   * Should sessions be tracked to Sentry Health or not.
   */
  public enableAutoSessionTracking?: boolean;

  /**
   * The interval to end a session if the App goes to the background.
   */
  public sessionTrackingIntervalMillis?: number;

  /**
   * Enable scope sync from Java to NDK on Android
   */
  public enableNdkScopeSync?: boolean = true;

  /**
   * When enabled, all the threads are automatically attached to all logged events on Android
   */
  public attachThreads?: boolean;

  /**
   * Enables Out of Memory Tracking for iOS and macCatalyst.
   * See the following link for more information and possible restrictions:
   * https://docs.sentry.io/platforms/apple/guides/ios/configuration/out-of-memory/
   *
   * @default true
   * */
  public enableOutOfMemoryTracking?: boolean;

  /**
   * Instrumentation that creates routing change transactions. By default creates
   * pageload and navigation transactions.
   */
  public routingInstrumentation?: typeof routingInstrumentation = instrumentAngularRouting;
}
