import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { OAuthGuard as TtOAuthGuard, OAuthService, UserInterface, UserService } from '@techniek-team/oauth';
import { InvalidGrantError } from '../errors/invalid-grant.error';

@Injectable({
  providedIn: 'root',
})
export class OAuthGuard extends TtOAuthGuard {

  constructor(
    oauthService: OAuthService,
    userService: UserService,
    toastController: ToastController,
    storage: Storage,
    private router: Router,
  ) {
    super(oauthService, userService, toastController, storage);
  }

  public override checkUserRoles(_currentUser: UserInterface, route: ActivatedRouteSnapshot): void {
    const grants: string[] = this.fetchGrants(route);

    if (grants.length === 0) {
      return;
    }

    const isGranted: boolean = this.userService.isGranted(grants);

    if (!isGranted) {
      throw new InvalidGrantError('InvalidGrantError!', grants);
    }
  }

  public fetchGrants(route: ActivatedRouteSnapshot): string[] {
    if (route.data && 'grants' in route.data) {
      return route.data['grants'];
    }
    return [];
  }

  protected override async handleError(error: Error): Promise<boolean | UrlTree> {
    let response: boolean | UrlTree = await super.handleError(error);
    if (response) {
      return Promise.resolve(response);
    }

    switch (true) {
      case error instanceof InvalidGrantError:
        if (this.userService.hasRole('ROLE_SKOLEO_TT_PLANNER_TUTOR')) {
          return this.router.parseUrl('/home');
        } else if (this.userService.hasRole('GRANT_APPLICANT')) {
          return this.router.parseUrl('/sollicitatie');
        }
        return this.router.parseUrl('/geen-toegang');
    }

    return Promise.resolve(false);
  }
}
