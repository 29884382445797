import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { declineReasonActions } from '@tutor-app/data-access-decline-reasons';
import { of, tap } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, withLatestFrom } from 'rxjs/operators';
import { AssignmentApi } from '../api/assignment.api';
import { assignmentsActions } from '../assignment.actions';
import { AssignmentSelectors } from '../assignment.selectors';

@Injectable()
export class AssignmentEffects {
  private loadOnSetSelectedAssignment$;

  private loadSelectedAssignment$;

  private assignmentFailure$;

  private assignmentSelectFailure$;

  private setLastCandidateRejections$;

  private resetLastCandidateRejectionsOnUndo$;

  private initAssignments$;

  private reloadAssignments$;

  private loadAssignments$;

  constructor(
    private assignmentApi: AssignmentApi,
    private actions$: Actions,
    private toastService: ToastService,
    private sentryErrorHandler: SentryErrorHandler,
    private store: Store,
  ) {
    this.loadOnSetSelectedAssignment$ = this.createLoadOnSetSelectedAssignmentEffect();
    this.loadSelectedAssignment$ = this.createLoadSelectedAssignmentEffect();
    this.assignmentFailure$ = this.createAssignmentFailureEffect();
    this.assignmentSelectFailure$ = this.createAssignmentSelectFailureEffect();
    this.setLastCandidateRejections$ = this.createSetLastCandidateRejectionsEffect();
    this.resetLastCandidateRejectionsOnUndo$ = this.createResetLastCandidateRejectionsOnUndoEffect();
    this.initAssignments$ = this.createInitAssignmentsEffect();
    this.reloadAssignments$ = this.createReloadAssignmentsEffect();
    this.loadAssignments$ = this.createLoadAssignmentsEffect();
  }

  private createLoadOnSetSelectedAssignmentEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.setSelectedAssignment),
        withLatestFrom(this.store.select(AssignmentSelectors.getSelectedId).pipe(startWith(undefined))),
        filter(([action, previousSelectedId]) => {
          return (action.selectedId && (!previousSelectedId || previousSelectedId !== action.selectedId)) as boolean;
        }),
        map(([action]) => assignmentsActions.loadSelectedAssignment({ assignment: action.selectedId as string })),
      ),
    );
  }

  private createLoadSelectedAssignmentEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.loadSelectedAssignment),
        mergeMap((action) => {
          return this.assignmentApi.getAssignment(action.assignment).pipe(
            map(response => assignmentsActions.loadSelectedAssignmentSuccess({ assignment: response })),
            catchError(error => of(assignmentsActions.loadSelectedAssignmentFailure({ error: error }))),
          );
        }),
      ),
    );
  }

  private createAssignmentFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(
          assignmentsActions.loadAssignmentsFailure,
        ),
        tap((action) => {
          return this.sentryErrorHandler.captureError(action.error)
            .then(() => this.toastService.error({
              message: 'Er is iets mis gegaan bij het ophalen van de opdrachten',
              duration: 10000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            }));
        }),
      ), { dispatch: false });
  }

  private createAssignmentSelectFailureEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(
          assignmentsActions.loadSelectedAssignmentFailure,
        ),
        tap((action) => {
          return this.sentryErrorHandler.captureError(action.error)
            .then(() => this.toastService.error({
              message: 'Opdracht niet gevonden.',
              duration: 10000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            }));
        }),
      ), { dispatch: false });
  }

  private createSetLastCandidateRejectionsEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(assignmentsActions.rejectSelfAssignmentSuccess),
      map(({ candidateRejections }) => declineReasonActions.setLastCandidateRejections({ ids: candidateRejections })),
    ));
  }

  private createResetLastCandidateRejectionsOnUndoEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(assignmentsActions.undoLastSelfAssignmentRejectionSuccess),
      map(() => declineReasonActions.setLastCandidateRejections({ ids: null })),
    ));
  }


  private createInitAssignmentsEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.initAssignments),
        withLatestFrom(this.store.pipe(select(AssignmentSelectors.selectLoaded))),
        filter(([_, loaded]) => (loaded === false)),
        map(() => assignmentsActions.loadAssignments()),
      ),
    );
  }

  private createReloadAssignmentsEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.reloadAssignments),
        map(() => assignmentsActions.loadAssignments()),
      ),
    );
  }

  private createLoadAssignmentsEffect() {
    return createEffect(
      () => this.actions$.pipe(
        ofType(assignmentsActions.loadAssignments),
        mergeMap(() => {
          return this.assignmentApi.getAssignments().pipe(
            map(response => assignmentsActions.loadAssignmentsSuccess({ assignments: response })),
            catchError(error => of(assignmentsActions.loadAssignmentsFailure({ error: error }))),
          );
        }),
      ),
    );
  }
}
